import { UseImages } from '../../../components/interfaces';
import useSuspanse from '../../useSuspanse';


interface FacebookSDK {
    login: (response: (response: any) => void) => void;
    api: (url: string, param1: ((response: any) => void) | { [index: string]: string }, param2: ((response: any) => void) | { [index: string]: string }) => void;
}

declare const FB: FacebookSDK;

const login = () => new Promise<void>((resolve, reject) => {
    FB.login((response) => {
        if (response.authResponse) {
            console.log('Facebook login successful.');
            resolve();
            return;
        }
        console.log('User cancelled login or did not fully authorize.');
        reject(new Error('Login error'));
    });
});

const fetchImages = async () => {
    const response = await new Promise<any>((resolve, reject) =>  FB.api('/me/photos', { type: 'uploaded', fields: 'images' }, (response) => {
        if (!response || response.error) {
            reject(new Error('Error fetching facebook images', {
                cause: response?.error,
            }));
            return;
        }
        resolve(response)
    }));
    return response.data.map((row: any) => {
        const thumbnails = row.images;
        return {
            id: row.id,
            previewUrl: thumbnails[thumbnails.length - 1].source,
            url: thumbnails[0].source,
            name: '',
        }
    })
}

const useFacebookImages: UseImages = () => {
    useSuspanse('facebook-login', login)
    const images = useSuspanse('facebook-images', fetchImages)
    return {
        images,
        hasMore: false,
        fetchMore: () => Promise.resolve(),
        fetching: false,
        error: null,
    };
}

export default useFacebookImages;
