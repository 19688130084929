import styled, { css } from 'styled-components';
import React, { FC, useEffect, useMemo, useState } from 'react';

const LoadingTextContainer = styled.div<{ $forceDarkBackText?: boolean }>`
	color: ${(props) => (props.$forceDarkBackText ? '#ffffff' : props.theme.colors.textMain)};
	font: ${(props) => props.theme.fonts.largeBody};
	${(props) =>
		props.$forceDarkBackText &&
		css`
			font-weight: bold;
		`};
	margin-top: 16px;
	text-align: center;
`;

type LoadingTextWithDurationConfig = {
	text: React.ReactNode;
	duration: number;
};
export type LoadingTextConfig =
	| React.ReactNode
	| LoadingTextWithDurationConfig
	| Array<LoadingTextWithDurationConfig | React.ReactNode>;
const isDurationConfig = (config: unknown): config is LoadingTextWithDurationConfig => {
	if (!config || typeof config !== 'object') {
		return false;
	}
	return !!config && 'duration' in config && 'text' in config;
};
const getTextFromConfig = (config: React.ReactNode | LoadingTextWithDurationConfig): React.ReactNode => {
	if (isDurationConfig(config)) {
		return config.text;
	}
	return config;
};
const useLoadingText = (text: LoadingTextConfig) => {
	const [currentTextIndex, setCurrentTextIndex] = useState(0);
	const currentText = useMemo(() => {
		if (!Array.isArray(text)) {
			return getTextFromConfig(text);
		}
		return getTextFromConfig(text[currentTextIndex % text.length]);
	}, [text, currentTextIndex]);
	useEffect(() => {
		if (!Array.isArray(text)) {
			return undefined;
		}
		const currentConfig = text[currentTextIndex % text.length];
		if (!isDurationConfig(currentConfig)) {
			return undefined;
		}
		const timeout = setTimeout(() => {
			setCurrentTextIndex((prev) => prev + 1);
		}, currentConfig.duration);
		return () => clearInterval(timeout);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentTextIndex]);
	return currentText;
};
type LoadingTextProps = {
	children: LoadingTextConfig;
	forceDarkBackText?: boolean;
};
const LoadingText: FC<LoadingTextProps> = ({ children, forceDarkBackText }) => {
	const text = useLoadingText(children);
	return <LoadingTextContainer $forceDarkBackText={forceDarkBackText}>{text}</LoadingTextContainer>;
};

export default LoadingText;
