import { useZakekeEditMode } from '@zakeke/zakeke-customizer-react';

const useCustomizerEditModes = () => {
	const { isSharedDesignID, isLoadedDesignID } = useZakekeEditMode();
	const isEditDesign = !!isLoadedDesignID;
	const isSharedDesign = !!isSharedDesignID;
	return {
		isEditDesign,
		isSharedDesign,
	};
};

export default useCustomizerEditModes;
