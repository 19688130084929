import lru from 'lru-cache';
import { useEffect } from 'react';
import { ZakekeSuspenseError } from '@zakeke/zakeke-customizer-react';

export type SuspendedCacheValue<T = any> = {
	promise: Promise<T>;
	status: 'resolved' | 'pending' | 'error';
	result: T | null;
	error: Error | null;
};
export type UseSuspanseOptions<T> = {
	cache?: lru<string, SuspendedCacheValue>;
	onCompleted?: (data: T) => void;
};
export type PromiseCreator<T> = () => Promise<T | null>;

const defaultCache = new lru<string, SuspendedCacheValue>({
	max: 20,
	ttl: 1000 * 60 * 60,
});
const defaultOptions = {
	cache: defaultCache,
};

/**
 * Hook that suspends the component until the promise is resolved.
 * Also cache the promise to avoid multiple requests.
 */
const useSuspanse = <T>(
	key: string,
	promiseCreator: PromiseCreator<T>,
	options: UseSuspanseOptions<T> = defaultOptions,
): T => {
	const cacheKey = key;
	const cache = options.cache ?? defaultOptions.cache;
	const cacheValue = cache.get(cacheKey);

	useEffect(() => {
		if (cacheValue && cacheValue.status === 'resolved') {
			options.onCompleted?.(cacheValue.result);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cacheValue]);

	if (cacheValue && cacheValue.status === 'error') {
		throw new ZakekeSuspenseError(cacheKey, cache, `useSuspanse error: ${cacheValue.error?.message}`, {
			cause: cacheValue.error,
		});
	}

	if (cacheValue && cacheValue.status === 'resolved') return cacheValue.result;

	// eslint-disable-next-line no-throw-literal
	if (cacheValue && cacheValue.status === 'pending') throw cacheValue.promise as Promise<T | null>;

	const promise = promiseCreator();

	promise.then((result) => {
		cache.set(cacheKey, { promise, status: 'resolved', result, error: null });
	});

	promise.catch((error) => {
		cache.set(cacheKey, { promise, status: 'error', result: null, error });
	})

	cache.set(cacheKey, { promise, status: 'pending', result: null, error: null });

	throw promise;
};

export default useSuspanse;
