import React, { Suspense, useMemo, useState } from 'react';
import { Provider as JotaiProvider } from 'jotai';
import { ThemeProvider } from 'styled-components';
import { useZakekeTranslations, ZakekeProvider } from '@zakeke/zakeke-customizer-react';

import { CustomErrorBoundary } from './components/widgets/customErrorBoundary';
import { DialogsRenderer } from './components/widgets/dialogs';
import { DropdownRenderer } from './components/widgets/dropdownPortal';
import GlobalEvents from './components/dummies/globalEvents';
import PhotoEditorRenderer from './components/menu/images/photoEditorRenderer';
import ErrorDialog from './components/widgets/errorDialog';
import VtoRenderer from './components/app/vtoRenderer';
import GlobalStyles from './theme/GlobalStyles';
import ThemeUpdater from './theme/ThemeUpdater';
import UiSkeleton from './components/app/ui/uiSkeleton';
import GlobalLoader from './components/app/globalLoader';
import { defaultTheme } from './theme';

const UI = React.lazy(() => import(/* webpackPrefetch: true */ './components/app/ui'));

export type AppErrorDialogProps = {
	error: Error;
};
const AppErrorDialog: React.FC<AppErrorDialogProps> = ({ error }) => {
	const { T } = useZakekeTranslations();
	const errorMessage = useMemo(() => {
		console.log('error.message', error.message);
		if (error.message.includes('Feature not available')) {
			return T._('Feature not available');
		}
		if (error.message.includes('Product not found or deleted.') || error.message.includes('Error fetching data')) {
			return T._('There was an error loading. Click OK to reload the page');
		}
		return T._('Generic Error');
	}, [T, error]);

	const showButtons = useMemo(() => {
		return !error.message.includes('Feature not available');
	}, [error]);

	return (
		<ErrorDialog
			message={errorMessage}
			showButtons={showButtons}
			onClose={() => {
				window.location.reload();
			}}
		/>
	);
};

const App = () => {
	const [theme, setTheme] = useState(defaultTheme);
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyles />
			<JotaiProvider>
				<CustomErrorBoundary render={(e) => <AppErrorDialog error={e} />}>
					<Suspense fallback={<UiSkeleton />}>
						<ZakekeProvider>
							<VtoRenderer />
							<PhotoEditorRenderer />
							<DropdownRenderer />
							<DialogsRenderer />
							<ThemeUpdater onUpdate={setTheme} />
							<UI />
							<GlobalEvents />
							<GlobalLoader />
						</ZakekeProvider>
					</Suspense>
				</CustomErrorBoundary>
			</JotaiProvider>
		</ThemeProvider>
	);
};

export default App;
