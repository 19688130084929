import styled from 'styled-components';
import Skeleton from './skeleton';
import React, { FC } from 'react';

const GridSkeletonContainer = styled.div<{ $columns: number }>`
	display: grid;
	grid-template-columns: repeat(${props => props.$columns}, 1fr);
	grid-gap: 8px;
`;
const GridSkeletonTile = styled(Skeleton)<{ $aspectRatio?: number }>`
	aspect-ratio: ${props => props.$aspectRatio || 1.6};
`;

type GridSkeletonProps = {
	columns?: number;
}
const GridSkeleton: FC<GridSkeletonProps> = ({ columns = 2 }) => (
	<GridSkeletonContainer $columns={columns}>
		<GridSkeletonTile variant="rectangular" />
		<GridSkeletonTile variant="rectangular" />
		<GridSkeletonTile variant="rectangular" />
		<GridSkeletonTile variant="rectangular" />
		<GridSkeletonTile variant="rectangular" />
		<GridSkeletonTile variant="rectangular" />
		<GridSkeletonTile variant="rectangular" />
		<GridSkeletonTile variant="rectangular" />
		<GridSkeletonTile variant="rectangular" />
		<GridSkeletonTile variant="rectangular" />
		<GridSkeletonTile variant="rectangular" />
		<GridSkeletonTile variant="rectangular" />
	</GridSkeletonContainer>
);

export default GridSkeleton;
