import styled, { css, keyframes } from 'styled-components';
import React, { FC } from 'react';
import { darken, lighten } from 'polished';

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

type SkeletonVariant = 'text' | 'rectangular' | 'rounded' | 'circle';
const SkeletonContainer = styled.div<{
	$variant?: SkeletonVariant;
	$width?: string;
	$height?: string;
	$fontSize?: string;
}>`
    background-image: ${(props) => css`
		linear-gradient(to right, ${darken(0.035, props.theme.colors.backgroundSecondary)} 0%,
			 ${lighten(0.01, props.theme.colors.backgroundSecondary)} 20%, ${darken(
                    0.035,
                    props.theme.colors.backgroundSecondary,
            )} 40%, 
			 ${darken(0.035, props.theme.colors.backgroundSecondary)} 100%)
	`};
    background-size: 2000px 100%;
    animation: ${shimmer} 1.5s infinite linear;
    border-radius: 4px;
    ${(props) => props.$variant === 'text' && css`
		display: inline-block;
		width: ${props.$width || '100%'};
		height: ${props.$height || '1em'};
		font-size: ${props.$fontSize || '1em'};
		border-radius: 4px;
	`}
    ${(props) => props.$variant === 'rectangular' && css`
		width: ${props.$width || '100%'};
		height: ${props.$height || '100%'};
	`}
	${(props) => props.$variant === 'rounded' && css`
		width: ${props.$width || '100%'};
		height: ${props.$height || '100%'};
		border-radius: 24px;
	`}
	${(props) => props.$variant === 'circle' && css`
		width: ${props.$width || '100%'};
		height: ${props.$height || '100%'};
		border-radius: 50%;
	`}
`;

export type SkeletonProps = {
	variant?: SkeletonVariant;
	width?: string;
	height?: string;
	fontSize?: string;
} & React.HTMLAttributes<HTMLDivElement>;
const Skeleton: FC<SkeletonProps> = ({
	 variant = 'rectangular',
	 width,
	 height,
	 fontSize,
	 ...rootProps
}) => {
	return (
		<SkeletonContainer
			{...rootProps}
			$variant={variant}
			$width={width}
			$height={height}
			$fontSize={fontSize}
		/>
	);
}

export default Skeleton;
