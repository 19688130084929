import styled, { css } from 'styled-components';
import { MOBILE_BREAKPOINT_PX } from '../../shared/helpers';

export const MenuContainer = styled.div<{
	$opened?: boolean;
	$compactMode?: boolean;
}>`
	display: flex;
	flex-direction: column;
	justify-content: start;
	border-radius: calc(${(props) => props.theme.measures.borderRadius} * 6);
	border-color: ${(props) => props.theme.colors.borders};
	border-style: solid;
	border-width: ${(props) => props.theme.panels.borderWidth};
	overflow: hidden;
	position: absolute;
	inset: 0;
	background-color: ${(props) => props.theme.colors.backgroundSecondary};
	z-index: 10;

	@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
		top: auto;
		bottom: 0;
		left: 15px;
		right: 15px;
		height: ${(props) => (props.$compactMode ? 'auto' : 'calc(100% - 15px)')};
		width: auto;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		border-bottom: 0;
		transition: transform ease 0;
		transform: translateY(100%);
		z-index: 22;
		${(props) =>
			props.$opened &&
			css`
				&:not(:empty) {
					transform: translateX(0);
					transition-duration: 0.25s;
					/* transition-delay: 0.05s; */
				}
			`}
	}
`;
