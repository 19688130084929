import { MutableRefObject, useLayoutEffect } from 'react';
import { useEffect, useRef } from "react";

const useFreshRef = <T>(
	value: T,
	preferLayoutEffect = false
): MutableRefObject<T> => {
	const useEffectToUse = preferLayoutEffect ? useLayoutEffect : useEffect;
	const ref = useRef(value);
	useEffectToUse(() => {
		ref.current = value;
	});

	return ref;
};

export default useFreshRef;