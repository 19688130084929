import { useZakekeBackgroundEditImagesMacroCategories, useZakekeTranslations } from '@zakeke/zakeke-customizer-react';
import { ImageGalleryMacroCategory } from '../../../components/widgets/imagesGallery';

const useSellerGalleryCategories = () => {
	const getBackgroundImagesCategories = useZakekeBackgroundEditImagesMacroCategories();

	const { T } = useZakekeTranslations();

	let categories: ImageGalleryMacroCategory[] = [{ value: null, label: T._('Browse categories') }];

	if (getBackgroundImagesCategories && getBackgroundImagesCategories.length > 0) {
		categories = categories.concat(
			getBackgroundImagesCategories
				.filter((x: any) => x.hasImages)
				.map((x: any) => ({
					value: x.macroCategoryID,
					options: x.categories
						.filter((x: any) => x.hasImages)
						.map((y: any) => ({
							value: y.categoryID,
							label: y.name,
						})),
					label: x.name,
				})),
		);
	}

	return categories;
};

export default useSellerGalleryCategories;
