import { RefObject, useEffect, useState } from 'react';

export const useTooltipEllipsis = (
	initialText: string | undefined,
	ref: RefObject<any>,
	isOverflowEnabled: boolean,
) => {
	const [isOverflowing, setIsOverflowing] = useState(false);

	useEffect(() => {
		const checkOverflow = () => {
			if (isOverflowEnabled) {
				const element = ref.current;
				if (element) {
					setIsOverflowing(element.offsetWidth < element.scrollWidth);
				}
			}

			checkOverflow();
			window.addEventListener('resize', checkOverflow);
		};

		return () => {
			if (isOverflowEnabled) {
				window.removeEventListener('resize', checkOverflow);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialText]);

	return isOverflowing;
};
