import { useZakekeClipartsPaginated } from '@zakeke/zakeke-customizer-react';
import { isString } from 'lodash';
import { useMemo } from 'react';
import { UseImages } from '../../../components/interfaces';

const useClipartsImages: UseImages = (categoryId: string | number | null, filter: string) => {
	const cat = useMemo(() => {
		if (isString(categoryId)) {
			return parseInt(categoryId);
		}
		return categoryId;
	}, [categoryId]);

	const images = useZakekeClipartsPaginated(cat, filter);

	return useMemo(
		() => ({
			...images,
			images: (images.data ?? []).map((image) => {
				return {
					id: image.imageID.toString(),
					previewUrl: image.choiceUrl,
					url: image.url,
					name: image.name,
					type: image.type,
					containsRaster: image.containsRaster,
				};
			}),
		}),
		[images],
	);
};

export default useClipartsImages;
