import styled, { css } from 'styled-components';
import { SMALLDESK_BREAKPOINT_PX } from '../../../shared/helpers';

export const UiLayout = styled.div<{ $isMobile?: boolean; $isPreview3DOpened?: boolean }>`
	display: grid;
	width: 100%;
	height: 100%;
	background-color: ${(props) => props.theme.colors.backgroundCanvas};
	color: ${(props) => props.theme.colors.textMain};

	grid-template-rows: minmax(0, 1fr) auto;
	grid-template-columns: 0fr 1fr auto;
	grid-template-areas:
		'preview3d customizer right-panel'
		'footer footer footer';

	${(props) =>
		props.$isPreview3DOpened &&
		css`
			grid-template-columns: minmax(200px, 28%) 1fr auto;
			grid-template-areas:
				'preview3d customizer right-panel'
				'footer footer footer';
		`};

	${(props) =>
		props.$isMobile &&
		css`
			grid-template-columns: 1fr;
			grid-template-areas:
				'customizer'
				'productinfo'
				'footer';
		`};
`;

export const UiLayoutRightPanelContainer = styled.div<{ $expanded?: boolean }>`
	position: relative;
	grid-area: right-panel;
	display: grid;
	column-gap: 16px;
	grid-template-rows: 60px minmax(0, 1fr);
	grid-template-columns: minmax(425px, 200px) auto;
	grid-template-areas:
		'actions tools-actions'
		'pages tools';
	padding-bottom: 16px;
	padding-inline-end: 30px;
	z-index: 1;
	${(props) =>
		!props.$expanded &&
		css`
			@media screen and (max-width: ${SMALLDESK_BREAKPOINT_PX}) {
				grid-template-columns: minmax(400px, 200px) auto;
				padding-inline-end: 20px;
			}
		`}
	${(props) =>
		props.$expanded &&
		css`
			grid-column-start: customizer;
			grid-column-end: right-panel;
			padding-inline-start: 32px;
			grid-template-columns: 1fr auto;
			z-index: 2;
		`};
`;

export const UiLayoutRightPanelActionsContainer = styled.div`
	position: relative;
	grid-area: actions;
	display: flex;
	flex-direction: row;
	align-items: center;
	align-content: center;
	justify-content: flex-end;
	gap: 8px;
`;

export const UiLayoutRightPanelToolsActionsContainer = styled.div`
	position: relative;
	grid-area: tools-actions;
	display: flex;
	align-items: center;
	background-color: ${(props) => props.theme.colors.backgroundSecondary};
	overflow: auto;
`;

export const UiLayoutRightPanelPagesContainer = styled.div`
	position: relative;
	grid-area: pages;
`;

export const UiLayoutRightPanelToolsContainer = styled.div`
	position: relative;
	grid-area: tools;
	display: flex;
	background-color: ${(props) => props.theme.colors.backgroundSecondary};
	border-color: ${(props) => props.theme.colors.borders};
	border-style: solid;
	border-width: ${(props) => props.theme.panels.borderWidth};
	border-inline: 0;
	border-top: 0;
	border-radius: 0 0 calc(${(props) => props.theme.measures.borderRadius} * 4)
		calc(${(props) => props.theme.measures.borderRadius} * 4);
	overflow: auto;
`;

export const UiLayoutCustomizerContainer = styled.div<{ $expanded?: boolean }>`
	position: relative;
	grid-area: customizer;
	z-index: 2;
	max-width: 30000px;
	max-height: 30000px;
	${(props) =>
		props.$expanded &&
		css`
			z-index: 1;
		`};
`;
