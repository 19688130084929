import React, { FC, ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { tooltipPortal } from '../../../shared/portals';
import { useTooltipEllipsis } from '../../../hooks/useTooltipEllipsis';
import { MOBILE_BREAKPOINT_PX, isMobile, isTouchOnly } from '../../../shared/helpers';

export type Position =
	| 'top'
	//   | "top-start"
	//   | "top-end"
	| 'bottom'
	//   | "bottom-start"
	//   | "bottom-end"
	| 'left'
	//   | "left-start"
	//   | "left-end"
	| 'right';
//   | "right-start"
//   | "right-end"

interface Props {
	title?: string;
	html?: string | ReactNode;
	interactive?: boolean;
	position?: Position;
	children?: String | React.ReactNode;
	isOverflowEnabled?: boolean;
	addContainer?: boolean;
	enterDelay?: number;
	exitDelay?: number;
}

interface TooltipBoxProps extends Props {
	onTooltipEnter: () => void;
	onTooltipExit: () => void;
	containerRef: React.RefObject<any>;
}

interface PropsBox {
	$containerX: number;
	$containerY: number;
	$position?: Position;
}

interface PropsArrow {
	$containerX: number;
	$containerY: number;
	$position?: Position;
}

const TooltipBox = styled.div<PropsBox>`
	position: fixed;
	box-shadow: 0 4px 20px 4px rgb(0 20 60 / 10%), 0 4px 80px -8px rgb(0 20 60 / 20%);
	left: ${(props) => props.$containerX}px;
	top: ${(props) => props.$containerY}px;
	padding: 0.4rem 0.8rem;
	border-radius: ${(props) => props.theme.measures.borderRadius};
	width: max-content;
	max-width: 400px;
	font-size: 14px;
	word-break: break-word;
	z-index: 30;
	text-align: center;
	color: ${(props) => props.theme.colors.textMain};
	background-color: ${(props) => props.theme.colors.backgroundMain};
	-webkit-font-smoothing: antialiased;
	-webkit-perspective: 800px;
	perspective: 800px;
	transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
	opacity: 1;
	white-space: pre-wrap;
	pointer-events: none;
	@media (max-width: ${MOBILE_BREAKPOINT_PX}) {
		max-width: calc(100vw - 30px);
	}
`;

const TooltipArrow = styled.div<PropsArrow>`
	position: fixed;
	border-width: 7px;
	border-style: solid;
	left: ${(props) => props.$containerX}px;
	top: ${(props) => props.$containerY}px;
	${(props) =>
		!props.$position && `border-color: ${props.theme.colors.backgroundMain} transparent transparent transparent;`}
	${(props) =>
		props.$position === 'top' &&
		`border-color: ${props.theme.colors.backgroundMain} transparent transparent transparent;`}
  ${(props) =>
		props.$position === 'left' &&
		`border-color: transparent transparent transparent ${props.theme.colors.backgroundMain};`}
  ${(props) =>
		props.$position === 'bottom' &&
		`border-color: transparent transparent ${props.theme.colors.backgroundMain} transparent;`}
  ${(props) =>
		props.$position === 'right' &&
		`border-color: transparent ${props.theme.colors.backgroundMain} transparent transparent;`}
`;

const TooltipTitle = styled.div`
	padding: 10px;
	font-size: 14px;
`;

function calculateExceedingWidth(containerRefBBox: DOMRect, tooltipRefBBox: DOMRect) {
	const tolltipExceedRight =
		window.innerWidth < containerRefBBox.left + containerRefBBox.width / 2 + tooltipRefBBox.width / 2;
	const tolltipExceedLeft = containerRefBBox.left + containerRefBBox.width / 2 - tooltipRefBBox.width / 2 < 0;
	return [tolltipExceedRight, tolltipExceedLeft];
}

const TooltipBoxRef: FC<TooltipBoxProps> = ({
	containerRef,
	html,
	title,
	interactive,
	position,
	onTooltipEnter,
	onTooltipExit,
}) => {
	const delay = 200;
	const tooltipRef = useRef<HTMLDivElement>(null);
	const [tooltipX, setTooltipX] = useState(0);
	const [tooltipY, setTooltipY] = useState(0);
	const [arrowX, setArrowX] = useState(0);
	const [arrowY, setArrowY] = useState(0);

	const handlePositionTooltip = () => {
		const containerRefBBox = containerRef.current.getBoundingClientRect();
		const tooltipRefBBox = tooltipRef!.current!.getBoundingClientRect();
		const [tooltipExceedRight, tooltipExceedLeft] = calculateExceedingWidth(containerRefBBox, tooltipRefBBox);

		const differenceX = tooltipExceedRight
			? window.innerWidth -
			  (containerRef.current.getBoundingClientRect().left +
					containerRef.current.getBoundingClientRect().width / 2 +
					tooltipRefBBox.width / 2) -
			  15
			: tooltipExceedLeft
			? -1 * (containerRefBBox.left + containerRefBBox.width / 2 - tooltipRefBBox.width / 2) + 15
			: 0;

		switch (position) {
			case 'top': {
				setTooltipX(
					containerRefBBox.left + containerRefBBox.width / 2 - tooltipRefBBox.width / 2 + differenceX,
				);
				setTooltipY(containerRefBBox.top - 10 - tooltipRefBBox.height);
				break;
			}
			case 'bottom': {
				setTooltipX(
					containerRefBBox.left + containerRefBBox.width / 2 - tooltipRefBBox.width / 2 + differenceX,
				);
				setTooltipY(containerRefBBox.bottom + tooltipRefBBox.height - 20);
				break;
			}
			case 'left': {
				setTooltipX(containerRefBBox.left - 15 - tooltipRefBBox.width);
				setTooltipY(containerRefBBox.top + containerRefBBox.height / 2 - tooltipRefBBox.height / 2);
				break;
			}
			case 'right': {
				setTooltipX(containerRef.current.getBoundingClientRect().right + 15);
				setTooltipY(
					containerRef.current?.getBoundingClientRect().top +
						containerRef.current.getBoundingClientRect().height / 2 -
						tooltipRefBBox.height / 2,
				);
				break;
			}
		}
	};

	const handlePositionArrow = () => {
		const tooltipRefBBox = tooltipRef!.current!.getBoundingClientRect();

		switch (position) {
			case 'top': {
				setArrowX(tooltipRefBBox.width / 2 - 12.8 / 2);
				setArrowY(tooltipRefBBox.bottom);
				break;
			}
			case 'bottom': {
				setArrowX(tooltipRefBBox.width / 2 - 12.8 / 2);
				setArrowY(tooltipRefBBox.top - 12.8);
				break;
			}
			case 'left': {
				setArrowX(tooltipRefBBox.right);
				setArrowY(tooltipRefBBox.height / 2 - 12.8 / 2);
				break;
			}
			case 'right': {
				setArrowX(tooltipRefBBox.left - 12.8);
				setArrowY(tooltipRefBBox.height / 2 - 12.8 / 2);
				break;
			}
		}
	};

	useLayoutEffect(() => {
		if (containerRef.current && tooltipRef.current) {
			handlePositionTooltip();
			handlePositionArrow();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [containerRef, tooltipRef]);

	return (
		<TooltipBox
			ref={tooltipRef}
			$containerX={tooltipX}
			$containerY={tooltipY}
			$position={position}
			onMouseEnter={() => interactive && setTimeout(() => onTooltipEnter(), delay)}
			onMouseLeave={() => interactive && setTimeout(() => onTooltipExit(), delay)}
		>
			{title && <TooltipTitle>{title}</TooltipTitle>}
			{html && typeof html === 'string' ? <div dangerouslySetInnerHTML={{ __html: html }}></div> : html}
			<TooltipArrow $containerX={arrowX} $containerY={arrowY} $position={position} />
		</TooltipBox>
	);
};

const Tooltip: FC<Props> = ({
	children,
	enterDelay = 0,
	exitDelay = 0,
	html,
	title,
	interactive,
	position = 'top',
	isOverflowEnabled = false,
	addContainer,
	...rest
}) => {
	const [show, setShow] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);
	const closeTimeoutRef = useRef<number | null>(null);

	const childElement = typeof children == 'string' ? <span>{children}</span> : children;
	const hookisOverflowing = useTooltipEllipsis(title, containerRef, isOverflowEnabled);
	const isOverflowing = isOverflowEnabled && title && hookisOverflowing;

	useEffect(() => {
		const handleScroll = () => {
			setShow(false);
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const internalInteractive = interactive === undefined ? true : interactive;

	const handleInteractiveEnter = () => {
		if (closeTimeoutRef.current) {
			clearTimeout(closeTimeoutRef.current);
		}

		setShow(true);
	};

	const handleInteractiveExit = () => {
		setShow(false);
	};

	const isTooltipVisible = (!isOverflowEnabled || isOverflowing) && show && (title || html);

	if (isTouchOnly() || isMobile()) return <>{childElement}</>;

	return (
		<>
			{isTooltipVisible &&
				createPortal(
					<TooltipBoxRef
						containerRef={containerRef}
						html={html}
						interactive={internalInteractive}
						position={position}
						onTooltipEnter={handleInteractiveEnter}
						onTooltipExit={handleInteractiveExit}
					></TooltipBoxRef>,
					tooltipPortal,
				)}

			{addContainer ? (
				<div
					ref={containerRef}
					onMouseEnter={() => {
						setShow(true);
					}}
					onMouseLeave={() => {
						setTimeout(() => setShow(false), exitDelay);
					}}
				>
					{childElement}
				</div>
			) : (
				React.cloneElement(childElement as React.ReactElement<any>, {
					...rest,
					ref: containerRef,
					onMouseEnter: () => {
						setTimeout(() => setShow(true), enterDelay);
					},
					onMouseLeave: () => {
						closeTimeoutRef.current = setTimeout(() => setShow(false), exitDelay) as unknown as number;
					},
				})
			)}
		</>
	);
};

export default Tooltip;
