export type PageCloseClickOptions = {
	setPreventDefaultCloseBehaviour: (b: boolean) => void;
};

export type onClosePage = ((e: PageCloseClickOptions) => void) | void;

/**
 * needed to pass more info about how page should bheave on close
 */
export const pageCloseClickDecorator = (onCloseClick: onClosePage, defaultPreventDefaultCloseBehaviour = false) => {
	const options = {
		preventDefaultCloseBehaviour: defaultPreventDefaultCloseBehaviour,
	};

	onCloseClick?.({
		setPreventDefaultCloseBehaviour: (val) => {
			options.preventDefaultCloseBehaviour = val;
		},
	} as PageCloseClickOptions);

	return options;
};

/*
default prevent Close Behaviour, but in callback you can change this.
*/
export const pageDefaultCloseBehavior = (onClose: onClosePage) => (opt: PageCloseClickOptions) => {
	if (onClose) opt.setPreventDefaultCloseBehaviour(true);
	onClose?.(opt);
};



export type ActionOptions = {
	setPreventDefault: (b: boolean) => void;
};

export type onAction = ((e: ActionOptions) => void) | void;

/**
 * needed to pass more info about how page should bheave on close
 */
export const actionDecorator = (onCloseClick: onAction, defaultPreventDefault = false) => {
	const options = {
		isDefaultPrevented: defaultPreventDefault,
	};

	onCloseClick?.({
		setPreventDefault: (val) => {
			options.isDefaultPrevented = val;
		},
	} as ActionOptions);

	return options;
};

/*
default prevent Close Behaviour, but in callback you can change this.
*/
export const actionDefaultBehavior = (onClose: onAction) => (opt: ActionOptions) => {
	if (onClose) opt.setPreventDefault(true);
	onClose?.(opt);
};