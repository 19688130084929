import { FC } from 'react';
import { ToolsListContainer, ToolsListItemButton } from './toolsList';
import Skeleton from '../widgets/skeleton';

const IconSkeleton = () => (
	<Skeleton
		variant="rectangular"
		width="24px"
		height="24px"
	/>
)

const ToolsListSkeleton: FC = () => {
	return (
		<ToolsListContainer>
			<ToolsListItemButton icon={<IconSkeleton />}>
				<Skeleton
					variant="text"
					width="100%"
					fontSize="1em"
				/>
			</ToolsListItemButton>
			<ToolsListItemButton icon={<IconSkeleton />}>
				<Skeleton
					variant="text"
					width="100%"
					fontSize="1em"
				/>
			</ToolsListItemButton>
			<ToolsListItemButton icon={<IconSkeleton />}>
				<Skeleton
					variant="text"
					width="100%"
					fontSize="1em"
				/>
			</ToolsListItemButton>
			<ToolsListItemButton icon={<IconSkeleton />}>
				<Skeleton
					variant="text"
					width="100%"
					fontSize="1em"
				/>
			</ToolsListItemButton>
			<ToolsListItemButton icon={<IconSkeleton />}>
				<Skeleton
					variant="text"
					width="100%"
					fontSize="1em"
				/>
			</ToolsListItemButton>
			<ToolsListItemButton icon={<IconSkeleton />}>
				<Skeleton
					variant="text"
					width="100%"
					fontSize="1em"
				/>
			</ToolsListItemButton>
			<ToolsListItemButton icon={<IconSkeleton />}>
				<Skeleton
					variant="text"
					width="100%"
					fontSize="1em"
				/>
			</ToolsListItemButton>
		</ToolsListContainer>
	);
};

export default ToolsListSkeleton;
