import { FC } from 'react';
import styled from 'styled-components';
import { Icon } from '../../globals';

const EmptyMessageContainer = styled.div<{ $error?: boolean }>`
	display: flex;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	padding-bottom: 80px;
	font-size: 14px;
	color: ${(props) => (props.$error ? props.theme.colors.error : props.theme.colors.textMain)};

	${Icon} {
		width: 64px;
		height: 64px;
		margin-bottom: 16px;
		color: ${(props) => (props.$error ? props.theme.colors.error : props.theme.colors.textMain)};
	}
`;

export type EmptyMessageProps = {
	message: string;
	error?: boolean;
	icon?: JSX.Element;
};
const EmptyMessage: FC<EmptyMessageProps> = ({ message, error, icon }) => {
	return (
		<EmptyMessageContainer $error={error}>
			{icon && <Icon>{icon}</Icon>}
			<span>{message}</span>
		</EmptyMessageContainer>
	);
};

export default EmptyMessage;
