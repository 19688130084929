import { useZakekePremiumPhotos, useZakekeProduct } from "@zakeke/zakeke-customizer-react";
import { ImageGalleryImage } from "../../../components/widgets/imagesGallery";

const usePremiumPhotosCreateImage = () => {
  const { premiumPhotosImport } = useZakekePremiumPhotos();
  const product = useZakekeProduct();

  return async (galleryImage: ImageGalleryImage) => {
    const premiumPhoto = await premiumPhotosImport({
		mediaid: galleryImage.id,
		previewUrl: galleryImage.previewUrl,
		productID: Number(product?.id),
		width: Number(galleryImage.width),
		height: Number(galleryImage.height),
	});
    return premiumPhoto;
  }
}

export default usePremiumPhotosCreateImage;