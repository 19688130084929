import { useZakekeBackgroundEditImagesPaginated } from '@zakeke/zakeke-customizer-react';
import { isString } from 'lodash';
import { useMemo } from 'react';
import { UseImages } from '../../../components/interfaces';

const useSellerGalleryImages: UseImages = (categoryId: string | number | null) => {
	const cat = useMemo(() => {
		if (isString(categoryId)) {
			return parseInt(categoryId);
		}
		return categoryId;
	}, [categoryId]);

	const images = useZakekeBackgroundEditImagesPaginated(cat);

	return useMemo(() => {
		return {
			...images,
			images: (images.data ?? []).map((x) => ({
				id: x.imageID.toString(),
				previewUrl: x.choiceUrl,
				url: x.url,
				name: x.name,
				isFromSeller: true,
			}))
		};
	}, [images]);

};

export default useSellerGalleryImages;
