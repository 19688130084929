import { useSyncExternalStore } from 'react';
import { isMobileMatchMedia, isSmallContainerMedia, isSmallDeskMatchMedia } from '../shared/helpers';

export const useIsMobile = () => {
	return useSyncExternalStore(
		(onStoreChange) => {
			isMobileMatchMedia.addEventListener('change', onStoreChange);
			return () => isMobileMatchMedia.removeEventListener('change', onStoreChange);
		},
		() => isMobileMatchMedia.matches,
	);
};

export const useIsSmallContainer = () => {
	return useSyncExternalStore(
		(onStoreChange) => {
			isSmallContainerMedia.addEventListener('change', onStoreChange);
			return () => isSmallContainerMedia.removeEventListener('change', onStoreChange);
		},
		() => isSmallContainerMedia.matches,
	);
};

export const useIsSmallDesk = () => {
	return useSyncExternalStore(
		(onStoreChange) => {
			isSmallDeskMatchMedia.addEventListener('change', onStoreChange);
			return () => isSmallDeskMatchMedia.removeEventListener('change', onStoreChange);
		},
		() => isSmallDeskMatchMedia.matches,
	);
};
