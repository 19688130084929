import { useZakekeHelpers } from '@zakeke/zakeke-customizer-react';
import { uniqueId } from 'lodash';
import { ImageGalleryImage } from '../../../components/widgets/imagesGallery';

const useFacebookCreateImages = () => {
	const { createImage } = useZakekeHelpers();

	return async (galleryImage: ImageGalleryImage) => {
		const response = await fetch(galleryImage.url);
		const file = new File([await response.blob()], 'facebook-' + uniqueId() + '.png');
		const result = await (() => {
			try {
				return createImage(file);
			} catch(e) {
				return  { errorCode: 'GENERIC' } as Awaited<ReturnType<typeof createImage>>;
			}
		})();
		if (result.errorCode !== 'NONE') throw new Error(result.errorCode);

		return result.result;
	};
};

export default useFacebookCreateImages;
