/* eslint-disable no-loop-func */
import React, { FC, useEffect, useState } from 'react';
import update from 'immutability-helper';
import { camelCase, capitalize, startCase, uniqBy } from 'lodash';
import styled, { css } from 'styled-components';
import {
	ProductVariant,
	createElementDescsGuid,
	createModificationGuid,
	useZakekeAddToCart,
	useZakekeBulkAndNameNumber,
	useZakekeStoreProductVariants,
	useZakekeTranslations,
} from '@zakeke/zakeke-customizer-react';

import useSelectedVariant from '../../hooks/useSelectedVariant';
import { stringFormat } from '../../shared/helpers';
import { ReactComponent as TrashIcon } from '../../shared/icons/delete.svg';
import { ReactComponent as AddIcon } from '../../shared/icons/plus-solid.svg';
import { ReactComponent as AccordionOpenCloseIcon } from '../../shared/icons/angle-down-solid.svg';
import { DesignItemTagType } from '../../state/interfaces/designItemTagType';
import { useAppStore, useItemsValue } from '../../state/store';
import AdvancedSelect from '../widgets/advancedSelect';
import { TextButton } from '../widgets/button';
import { Dialog, DialogWindow, DialogFooter, useDialogManager } from '../widgets/dialogs';
import { CustomizerElement } from '../interfaces';
import { FormControl, Icon, Input } from '../globals';
import { useIsMobile } from '../../hooks/useLayoutCheck';

const CustomWindow = styled(DialogWindow)`
	max-width: 1200px;
	min-height: 400px;
	padding: 25px 0;
	font-size: 14px;

	${DialogFooter} {
		padding-left: 50px;
		padding-right: 50px;
	}

	${TextButton} {
		margin-top: 20px;
		margin-left: auto;
		margin-right: auto;
	}
`;

interface ItemRow {
	descGuid: string;
	modificationGuid: string;
	values: Map<string, string>;
	hasError: Map<string, boolean>;
	attributes: Map<string, string | null | undefined>;
	quantity: string;
}

const TotalPriceInput = styled(Input)`
	font-weight: bold;
`;

const FooterContent = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
`;

const FooterContentError = styled.span`
	color: ${(props) => props.theme.colors.error};
`;

const OutOfStock = styled.span`
	color: ${(props) => props.theme.colors.error};
	font-size: 10px;
	display: inline-block;
	margin-left: 5px;
`;

const createRow = (
	items: CustomizerElement[],
	attributes: StoreAttribute[],
	initialSelectedVariant?: ProductVariant,
	initialQty?: number,
): ItemRow => {
	return {
		modificationGuid: createModificationGuid(),
		descGuid: createElementDescsGuid(),
		quantity: initialQty ? initialQty.toString() : '1',
		values: new Map(items.map((x) => [x.tag!, ''])),
		hasError: new Map(items.map((x) => [x.tag!, false])),
		attributes: new Map(
			attributes.map((x) => {
				if (initialSelectedVariant) {
					const initialValue = initialSelectedVariant.variantCode.find((variant) => variant.Id === x.id)
						?.Value.Id;
					if (initialValue) return [x.id, initialValue];
				}
				return [x.id, ''];
			}),
		),
	};
};

interface StoreAttribute {
	id: string;
	label: string;
	values: {
		id: string;
		label: string;
	}[];
}

interface StoreVariant {
	Id: string;
	Value: {
		Id: string;
	};
}

interface ProductPrice {
	isOutOfStock: boolean;
	finalPrice: number;
}


const VariantAccordionContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.colors.borders};
`;
const VariantAccordionHeaderContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
`;
const VariantAccordionContentContainer = styled.div<{ $open?: boolean }>`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 12px;
	
	transition: max-height 0.3s ease;
	
	max-height: 2000px;
	${(props) => !props.$open && css`
		max-height: 0;
		overflow: hidden;
	`}
`;

const VariantAccordionControlContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
    gap: 12px;
`;

const VariantAccordionControlContainerLabel = styled.label`
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
	width: 30%;
`;

const VariantAccordionHeaderActionsContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const VariantAccordionHeaderAction = styled.button`
	background: none;
	border: none;
	cursor: pointer;
`;

type VariantAccordionProps = {
	variantIndex: number;
	numRows: number;
	children: React.ReactNode;
	onRemove: () => void;
	defaultIsOpen?: boolean;
};
const VariantAccordion: FC<VariantAccordionProps> = (props) => {
	const [open, setOpen] = useState(props.defaultIsOpen ?? false);
	const showDeleteButton = (numRows: number): boolean => numRows > 1;
	return (
		<VariantAccordionContainer>
			<VariantAccordionHeaderContainer onClick={() => setOpen((prev) => !prev)}>
				<h3>#{props.variantIndex + 1}</h3>
				<VariantAccordionHeaderActionsContainer>
					<VariantAccordionHeaderAction onClick={(e) => {
						e.stopPropagation();
						setOpen((prev) => !prev);
					}}>
						<Icon $rotate={!open ? '0' : '180deg'}>
							<AccordionOpenCloseIcon />
						</Icon>
					</VariantAccordionHeaderAction>
					{showDeleteButton(props.numRows) && (
						<VariantAccordionHeaderAction onClick={() => props.onRemove()}>
							<Icon>
								<TrashIcon />
							</Icon>
						</VariantAccordionHeaderAction>
					)}
				</VariantAccordionHeaderActionsContainer>
			</VariantAccordionHeaderContainer>
			<VariantAccordionContentContainer $open={open}>
				{props.children}
			</VariantAccordionContentContainer>
		</VariantAccordionContainer>
	);
}

const ActionsContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 0;
	margin-bottom: 40px;
	${TextButton} {
		font-size: 16px;
	}
`;

const VariantsContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const VariantAccordionsContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const VariantTableContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow: auto;
	table {
		border-spacing: 0;
        display: table;
        width: 100%;
        table-layout: fixed;
	}
	thead {
		z-index: 2;
        position: sticky;
        top: 0;
		background: ${({ theme }) => theme.colors.backgroundMain};
		hr {
            height: 1px;
            border: none;
			background-color: ${({ theme }) => theme.colors.borders};
			margin: 0;
			padding: 0;
		}
		tr:not(:nth-child(2)) {
			height: 40px;
        }
	}
	th {
		text-align: left;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
	}
	tbody {
        tr {
            height: 40px;
        }
	}
	th, td {
		padding: 8px;
	}
	th {
        width: 150px;
	}
    th:is(:last-child) {
        width: 80px;
    }
	th:is(:first-child) {
		width: 40px;
    }
    th:is(:nth-last-child(2)), th:is(:nth-last-child(3)), th:is(:nth-last-child(4)) {
		width: 100px;
	}
`;

const ActionButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;	
	padding: 0;
`;

const NameNumberDialog = () => {
	const isMobile = useIsMobile();
	const { currentDialogId, closeDialog } = useDialogManager();
	const [priceFormatter, editDesignId, nameNumberSelection, currentTemplate] = useAppStore((x) => [
		x.priceFormatter,
		x.editDesignId,
		x.nameNumberSelection,
		x.currentTemplate,
	]);
	const { addToCart, addToCartNameNumber } = useZakekeAddToCart();
	const { isSkipOptionForNameAndNumber, attributeCodes, getPrices, initialQty } = useZakekeBulkAndNameNumber();
	const getStoreVariants = useZakekeStoreProductVariants();
	const selectedVariant = useSelectedVariant();

	const items = useItemsValue();
	const validItems = items.filter((x) => x.tag && x.tagType === DesignItemTagType.TeamWear);

	const [prices, setPrices] = useState<(ProductPrice | null)[]>([]);
	const [unitPrices, setUnitPrices] = useState<(number | null)[]>([]);
	const [showErrors, setShowErrors] = useState(false);
	const [itemRows, setItemRows] = useState<ItemRow[]>([]);
	const [tags, setTags] = useState<string[]>([]);
	const [storeVariants, setStoreVariants] = useState<{ attributes: StoreAttribute[]; variants: StoreVariant[][] }>({
		attributes: [],
		variants: [],
	});

	const { T } = useZakekeTranslations();

	// Get attributes and transform to select options
	const filteredStoreAttributes = storeVariants.attributes.filter((x) => attributeCodes.includes(x.id));

	const storeValidAttributes = filteredStoreAttributes.map((attr) => ({
		...attr,
		values: attr.values.map((option) => ({
			value: option.id,
			label: option.label,
		})),
	}));

	const validateErrors = () => {
		let hasError = false;

		for (const index in itemRows) {
			const row = itemRows[index];

			for (const [tag, value] of row.values.entries()) {
				const item = validItems.find((x) => x.tag === tag)!;
				const minChars = item?.constraints?.minNrChars;
				const maxChars = item?.constraints?.maxNrChars;

				const invalidMin = minChars && minChars > 0 && value.length < minChars ? minChars : null;
				const invalidMax = maxChars && maxChars > 0 && value.length > maxChars ? maxChars : null;

				setItemRows((prev) =>
					update(prev, {
						[index]: {
							hasError: {
								[tag]: {
									$set: invalidMin !== null || invalidMax !== null,
								},
							},
						},
					}),
				);

				if (invalidMin !== null || invalidMax !== null) hasError = true;
			}
		}

		setShowErrors(hasError);
		return !hasError;
	};

	useEffect(() => {
		if (items && items.length > 0 && getStoreVariants) {
			getStoreVariants().then((x) => {
				const validAttributes = x.attributes.filter((x) => attributeCodes.includes(x.id));
				setStoreVariants(x);

				if (nameNumberSelection.length === 0) {
					setItemRows([createRow(validItems, validAttributes, selectedVariant, initialQty)]);
				} else {
					setItemRows(
						nameNumberSelection.map((x) => {
							return {
								modificationGuid: x.modificationGuid,
								descGuid: x.descGuid,
								quantity: x.quantity.toString(),
								values: x.values,
								hasError: new Map<string, boolean>(),
								attributes: x.attributes,
							};
						}),
					);
				}

				// Get unique tags
				setTags(uniqBy(validItems, (x) => x.tag).map((x) => x.tag!));
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	useEffect(() => {
		// Filter item values
		const selection = itemRows.map((x) => {
			return {
				attributes: x.attributes,
				quantity: parseInt(x.quantity),
			};
		});

		getPrices(selection).then((prices) => {
			const unitPrices = prices.map((x, id) => {
				if (x && parseInt(itemRows[id].quantity) > 0) return x.finalPrice / parseInt(itemRows[id].quantity);
				else return 0;
			});
			setPrices(prices);
			setUnitPrices(unitPrices);
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemRows]);

	const atLeastOnRowCompleted = prices.some((x) => x !== null && !x.isOutOfStock);

	const buttons = [
		{
			label: T._('Add to cart'),
			primary: true,
			outline: false,
			disabled: !atLeastOnRowCompleted,
			onClick: () => {
				if (validateErrors()) {
					closeDialog(currentDialogId);
					addToCartNameNumber({
						designId: editDesignId,
						templateId: currentTemplate?.templateId ?? null,
						nameNumberSelections: itemRows.map((x) => ({
							quantity: parseInt(x.quantity),
							values: x.values,
							modificationGuid: x.modificationGuid,
							descGuid: x.descGuid,
							attributes: x.attributes,
						})),
					});
				}
			},
		},
	];

	if (isSkipOptionForNameAndNumber) {
		buttons.unshift({
			label: T._('Skip'),
			primary: false,
			outline: true,
			disabled: false,
			onClick: () => {
				if (validateErrors()) {
					closeDialog(currentDialogId);
					addToCart({
						designId: editDesignId,
					});
				}
			},
		});
	}

	let firstError: { name: string; min: number | null | undefined; max: number | null | undefined } | null = null;
	for (const item of itemRows) {
		for (const [tag, error] of item.hasError) {
			if (error) {
				const item = items.find((x) => x.tag === tag)!;
				firstError = { name: item?.name, min: item.constraints?.minNrChars, max: item.constraints?.maxNrChars };
			}
		}
	}

	const footerContent = (
		<FooterContent>
			{showErrors && firstError && (
				<FooterContentError>
					{firstError.min !== null &&
						firstError.max !== null &&
						stringFormat(
							T._('The text {0} must respect the min of {1} chars and and max of {2} chars.'),
							firstError.name,
							firstError.min!.toString(),
							firstError.max!.toString(),
						)}
					{firstError.min !== null &&
						firstError.max === null &&
						stringFormat(
							T._('The text {0} must respect the min of {1} chars.'),
							firstError.name,
							firstError.min!.toString(),
						)}
					{firstError.min === null &&
						firstError.max !== null &&
						stringFormat(
							T._('The text {0} must respect the max of {1} chars.'),
							firstError.name,
							firstError.max!.toString(),
						)}
				</FooterContentError>
			)}
			<span>
				{T._('Total price')}:{' '}
				<strong>{priceFormatter.format(prices.reduce((acc, val) => acc + (val?.finalPrice ?? 0), 0))}</strong>
			</span>
		</FooterContent>
	);

	const renderTable = () => (
		<VariantTableContainer>
			<table>
				<thead>
				<tr>
					<th>#</th>
					{tags.map((tag) => {
						const item = validItems.find((x) => x.tag === tag)!;
						return <th key={tag}>{item.name}</th>;
					})}
					{storeValidAttributes.map((x) => (
						<th key={x.id}>{startCase(camelCase(x.label))}</th>
					))}
					<th>{T._('Quantity')}</th>
					<th>{T._('Price')}</th>
					<th>{T._('Total')}</th>
					<th></th>
				</tr>
				</thead>
				<tbody>
				{itemRows.map((itemRow, idx) => {
					const isCompleted = Array.from(itemRow.attributes.values()).filter((x) => !x).length === 0;
					const isRowInvalid = isCompleted && (prices[idx] === null || prices[idx]?.isOutOfStock);

					return (
						<React.Fragment key={itemRow.modificationGuid}>
							<tr>
								<td>{idx}</td>
								{Array.from(itemRow.values.keys()).map((tag) => {
									const item = validItems.find((x) => x.tag === tag)!;

									return (
										<td key={`${itemRow.descGuid}_${tag}`}>
											<FormControl label="">
												<Input
													$invalid={itemRow.hasError.get(tag)}
													placeholder={item?.name}
													value={itemRow.values.get(tag)}
													onChange={(e) =>
														setItemRows((prev) => {
															return update(prev, {
																[idx]: {
																	values: {
																		[tag]: {
																			$set: e.target.value,
																		},
																	},
																},
															});
														})
													}
												/>
											</FormControl>
										</td>
									);
								})}

								{storeValidAttributes.map((attribute) => {
									return (
										<td key={`${itemRow.descGuid}_${attribute.id}`}>
											<FormControl label="">
												<AdvancedSelect<{ value: string; label: string }>
													menuPosition='fixed'
													isSearchable={false}
													options={attribute.values}
													value={attribute.values.find(
														(x) => x.value === itemRow.attributes.get(attribute.id),
													)}
													onChange={(item) =>
														setItemRows((prev) => {
															return update(prev, {
																[idx]: {
																	attributes: {
																		[attribute.id]: {
																			$set: item?.value,
																		},
																	},
																},
															});
														})
													}
												/>
											</FormControl>
										</td>
									);
								})}

								<td>
									<Input
										type='number'
										min={1}
										step={1}
										$invalid={isRowInvalid}
										value={itemRow.quantity}
										onChange={(e) =>
											setItemRows((prev) => {
												return update(prev, {
													[idx]: {
														quantity: {
															$set: e.target.value,
														},
													},
												});
											})
										}
									/>

									{isRowInvalid && <OutOfStock>{T._('Out of stock')}</OutOfStock>}
								</td>
								<td>
									<Input value={priceFormatter.format(unitPrices[idx] ?? 0)} step='0.01'
										   readOnly />
								</td>
								<td>
									<TotalPriceInput
										value={priceFormatter.format(prices[idx]?.finalPrice ?? 0)}
										readOnly
									/>
								</td>
								<td>
									{itemRows.length > 1 && (
										<ActionButton onClick={() => setItemRows(itemRows.filter((x) => x !== itemRow))}>
											<Icon>
												<TrashIcon />
											</Icon>
										</ActionButton>
									)}
									<ActionButton onClick={(e) => setItemRows([...itemRows, createRow(validItems, filteredStoreAttributes)])}>
										<Icon>
											<AddIcon />
										</Icon>
									</ActionButton>
								</td>
							</tr>
						</React.Fragment>
					);
				})}
				</tbody>
			</table>
		</VariantTableContainer>
	);
	const renderAccordions = () => (
		<VariantAccordionsContainer>
			<VariantsContainer>
			{itemRows.map((itemRow, idx) => {
				const isCompleted = Array.from(itemRow.attributes.values()).filter((x) => !x).length === 0;
				const isRowInvalid = isCompleted && (prices[idx] === null || prices[idx]?.isOutOfStock);

				return (
					<VariantAccordion
						key={itemRow.modificationGuid}
						variantIndex={idx}
						defaultIsOpen={idx === 0}
						numRows={itemRows.length}
						onRemove={() => setItemRows(itemRows.filter((x) => x !== itemRow))}
					>
						<>
							{Array.from(itemRow.values.keys()).map((tag) => {
								const item = validItems.find((x) => x.tag === tag)!;

								return (
									<VariantAccordionControlContainer key={`${itemRow.descGuid}_${tag}`}>
										<VariantAccordionControlContainerLabel>
											<span>{capitalize(item.name)}</span>
										</VariantAccordionControlContainerLabel>
										<FormControl label="">
											<Input
												$invalid={itemRow.hasError.get(tag)}
												placeholder={item?.name}
												value={itemRow.values.get(tag)}
												onChange={(e) =>
													setItemRows((prev) => {
														return update(prev, {
															[idx]: {
																values: {
																	[tag]: {
																		$set: e.target.value,
																	},
																},
															},
														});
													})
												}
											/>
										</FormControl>
									</VariantAccordionControlContainer>
								);
							})}
							{storeValidAttributes.map((attribute) => {
								return (
									<VariantAccordionControlContainer key={`${itemRow.descGuid}_${attribute.id}`}>
										<VariantAccordionControlContainerLabel>
											<span>{capitalize(attribute.label)}</span>
										</VariantAccordionControlContainerLabel>
										<FormControl label="">
											<AdvancedSelect<{ value: string; label: string }>
												menuPosition='fixed'
												isSearchable={false}
												options={attribute.values}
												value={attribute.values.find(
													(x) => x.value === itemRow.attributes.get(attribute.id),
												)}
												onChange={(item) =>
													setItemRows((prev) => {
														return update(prev, {
															[idx]: {
																attributes: {
																	[attribute.id]: {
																		$set: item?.value,
																	},
																},
															},
														});
													})
												}
											/>
										</FormControl>
									</VariantAccordionControlContainer>
								);
							})}

							<VariantAccordionControlContainer>
								<VariantAccordionControlContainerLabel>
									<span>{capitalize(T._('Quantity'))}</span>
								</VariantAccordionControlContainerLabel>
								<Input
									type='number'
									min={1}
									step={1}
									$invalid={isRowInvalid}
									value={itemRow.quantity}
									onChange={(e) =>
										setItemRows((prev) => {
											return update(prev, {
												[idx]: {
													quantity: {
														$set: e.target.value,
													},
												},
											});
										})
									}
								/>

								{isRowInvalid && <OutOfStock>{T._('Out of stock')}</OutOfStock>}
							</VariantAccordionControlContainer>
							<VariantAccordionControlContainer>
								<VariantAccordionControlContainerLabel>
									<span>{capitalize(T._('Price'))}</span>
								</VariantAccordionControlContainerLabel>
								<Input value={priceFormatter.format(unitPrices[idx] ?? 0)} step='0.01'
									   readOnly />
							</VariantAccordionControlContainer>
							<VariantAccordionControlContainer>
								<VariantAccordionControlContainerLabel>
									<span>{capitalize(T._('Total'))}</span>
								</VariantAccordionControlContainerLabel>
								<TotalPriceInput
									value={priceFormatter.format(prices[idx]?.finalPrice ?? 0)}
									readOnly
								/>
							</VariantAccordionControlContainer>
						</>
					</VariantAccordion>
				);
			})}
		</VariantsContainer>
			<ActionsContainer>
				<TextButton icon={<AddIcon />} onClick={(e) => setItemRows([...itemRows, createRow(validItems, filteredStoreAttributes)])}>
					<strong>{T._('Add item')}</strong>
				</TextButton>
			</ActionsContainer>
		</VariantAccordionsContainer>
	)
	return (
		<Dialog
			variant={isMobile ? 'panel' : 'window'}
			windowDecorator={CustomWindow}
			title={T._('Name and numbers')}
			buttons={buttons}
			leftFooterCustomComponent={footerContent}
			alignTop={true}
			showCloseButton
		>
			{!isMobile && renderTable()}
			{isMobile && renderAccordions()}
		</Dialog>
	);
};

export default NameNumberDialog;
