import { useZakekeTranslations } from '@zakeke/zakeke-customizer-react';
import { Tool } from 'photoeditorsdk';

const usePhotoEditorTool = () => {
	const { T } = useZakekeTranslations();

	const photoEdtiorLabelsFromBackend = [
		'filter', // if active the tools, always active
		'focus', // optional
		'transform', // optional
		'brush', // optional
		'frame', // optional
		'adjustment', // optional
		'overlay', // optional
		/* 'sticker', */ // not available in pm
		/* 'text', */ // not available in pm
		/* 'library', */ // not available in pm
	];

	const photoEditorToolsList = {
		filter: Tool.FILTER,
		focus: Tool.FOCUS,
		transform: Tool.TRANSFORM,
		brush: Tool.BRUSH,
		frame: Tool.FRAME,
		adjustment: Tool.ADJUSTMENT,
		overlay: Tool.OVERLAY,
		// sticker: Tool.STICKER,
		// text: Tool.TEXT,
		// custom: Tool.CUSTOM,
		// library: Tool.LIBRARY,
	} as { [index: string]: Tool };

	const photoEditorToolsLabels = new Map([
		['filter', T._('Filter')],
		['focus', T._('Focus')],
		['transform', T._('Transform')],
		['brush', T._('Brush')],
		['frame', T._('Frame')],
		['adjustment', T._('Adjustment')],
		['overlay', T._('Overlay')],
		/* ['sticker', T._('Sticker')], */
		/* ['text', T._('Text')], */
		/* ['library', T._('Library')], */
	]);
	return {
		photoEditorToolsLabels,
		photoEdtiorLabelsFromBackend,
		photoEditorToolsList,
	};
};

export default usePhotoEditorTool;
