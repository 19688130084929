import { useZakekePremiumPhotos } from "@zakeke/zakeke-customizer-react";
import { ImageGalleryMacroCategory } from "../../../components/widgets/imagesGallery";
import { useMemo } from 'react';

const usePremiumPhotosCategories = () => {
  const { premiumPhotosCategories } = useZakekePremiumPhotos();

  return useMemo(() => {
    if (!premiumPhotosCategories) {
        return [];
    }
    return premiumPhotosCategories.map((x) => ({
      value: x.keyword,
      label: x.name,
      data: {
        urlImage: x.urlImage
      },
    } as ImageGalleryMacroCategory));
  }, [premiumPhotosCategories]);
}

export default usePremiumPhotosCategories;