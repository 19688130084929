import { useZakekeHelpers, useZakekeBackgroundEditRules, useZakekeUsedColors } from '@zakeke/zakeke-customizer-react';
import { useAppStore } from '../state/store';
import { BackgroundRule } from '../components/interfaces';
import usePrintMethods from './usePrintMethods';
import { uniqBy } from 'lodash';



function createRule(): BackgroundRule {
	return {
		colorList: null,
		enableImageCustomerGallery: false,
		enableImageSellerGallery: false,
		imageTypeID: null,
		enableNoColor: true,
		enableAllColors: true,
	};
}

export const useBackgroundEditingTools = (currentColor?: string|null) => {
	const [selectedSideId] = useAppStore((x) => [x.selectedSideId]);
	const { backgroundEditProductRule, backgroundEditPrintTypeRule, backgroundEditSideRule } =
		useZakekeBackgroundEditRules();
	const { getPrintMethodForSide } = usePrintMethods();
	const { getSelectableColorsForPrintType, getUsedColorsWithCount } = useZakekeHelpers();
	// aggiungere color in use se ha limite massimo di colori utilizzabili
	const actualPrintMethod = getPrintMethodForSide(selectedSideId);
	const usedColors = useZakekeUsedColors();
	
	const getSelectableColors = (() => {
		const getSelectableColors = getSelectableColorsForPrintType(actualPrintMethod?.id ?? null);
		if (getSelectableColors) return getSelectableColors;

		const sideUsedColors = uniqBy(usedColors?.get(selectedSideId) ?? [], i => i.toLowerCase());

		if (actualPrintMethod?.maxColors) {
			const sideUsedColorsWithUse = getUsedColorsWithCount(selectedSideId);
			const changeLastColor = currentColor && sideUsedColorsWithUse.has(currentColor.toLowerCase()) ? sideUsedColorsWithUse.get(currentColor.toLowerCase())! <= 1: false
			if (sideUsedColors!.length < actualPrintMethod.maxColors || changeLastColor) {
				return []; // show all bg color
			}
			return sideUsedColors.map(i => ({
				hex: i.toLowerCase(),
				code: i.toLowerCase(),
				name: i.toLowerCase(),
			})); // set used color to filter bg available colors
		}
		return null;
	})();




	const thisSideIdStr = '' + selectedSideId;
	let rule;
	// if null, background color tab will not be selectable
	let colorList: [] | null = null;

	if (
		backgroundEditPrintTypeRule &&
		backgroundEditPrintTypeRule.length === 0 &&
		!backgroundEditProductRule &&
		backgroundEditSideRule &&
		Object.keys(backgroundEditSideRule).length === 0
	)
		return rule;

	// PrintType Rule
	if (backgroundEditPrintTypeRule) {
		const ruleByPrintType = backgroundEditPrintTypeRule.find((x) => x.printTypeID === actualPrintMethod?.id)?.rule;
		if (ruleByPrintType) {
			rule = createRule();
			rule.enableImageCustomerGallery = ruleByPrintType.enableImageCustomerGallery;
			rule.enableImageSellerGallery = ruleByPrintType.enableImageSellerGallery;
			rule.imageTypeID = ruleByPrintType.imageTypeID;
			rule.enableNoColor = ruleByPrintType.enableNoColor;
			colorList = ruleByPrintType.colorList ? JSON.parse(ruleByPrintType.colorList) : ruleByPrintType.colorList;
		}
	}
	// Product rule fallback
	if (!rule && backgroundEditProductRule) {
		rule = createRule();
		rule.enableImageCustomerGallery = backgroundEditProductRule.enableImageCustomerGallery;
		rule.enableImageSellerGallery = backgroundEditProductRule.enableImageSellerGallery;
		rule.imageTypeID = backgroundEditProductRule.imageTypeID;
		rule.enableNoColor = backgroundEditProductRule.enableNoColor;
		colorList = backgroundEditProductRule.colorList
			? JSON.parse(backgroundEditProductRule.colorList)
			: backgroundEditProductRule.colorList;
	}
	// Side rule fallback
	if (!rule && backgroundEditSideRule) {
		if (backgroundEditSideRule[thisSideIdStr]) {
			const thisSideRule = backgroundEditSideRule[thisSideIdStr];
			rule = createRule();
			rule.enableImageCustomerGallery = thisSideRule.enableImageCustomerGallery;
			rule.enableImageSellerGallery = thisSideRule.enableImageSellerGallery;
			rule.imageTypeID = thisSideRule.imageTypeID;
			rule.enableNoColor = thisSideRule.enableNoColor;
			colorList = thisSideRule.colorList ? JSON.parse(thisSideRule.colorList) : thisSideRule.colorList;
		}
	}
	// Last checks
	if (rule) {
		if (colorList) {
			// we need to check if we need to filter colors based on general printRules
			const availableColors = getSelectableColors && getSelectableColors.length > 0 ? getSelectableColors : [];
			if (availableColors.length > 0) {
				rule.enableAllColors = false;

				if (colorList.length > 0) {
					rule.colorList = availableColors.filter(
						(x) => colorList!.findIndex((y: string) => y.toLowerCase() === x.hex.toLowerCase()) !== -1,
					);
					if (rule.colorList.length === 0) {
						// disable empty color list
						rule.colorList = null;
					}
				} else {
					rule.colorList = availableColors;
				}
			} else {
				if (colorList.length > 0) {
					rule.colorList = colorList.map((x: string) => {
						return { hex: x, code: '', name: '' };
					});
					rule.enableAllColors = false;
				} else {
					rule.colorList = [];
				}
			}
		}
	}
	return rule;
};
