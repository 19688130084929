import {
	FooterContainer,
	FooterDesktopContainer,
	FooterDesktopLeftActions,
	FooterDesktopRightActions,
	FooterDesktopRightActionsPriceInfoContainer,
	FooterMobileContainer,
	FooterMobileSeparator,
	FooterRightActionButtons,
	FooterRightCartInfo,
} from './footerLayout';
import React from 'react';
import Skeleton from '../../widgets/skeleton';
import styled from 'styled-components';
import { useIsMobile } from '../../../hooks/useLayoutCheck';
import { CustomizerButton } from '../../globals';

const PriceInfoSkeletonContainer = styled.div`
	text-align: right;
`;

const MobileButtonSkeleton = () => (
	<CustomizerButton>
		<Skeleton
			variant="rectangular"
			width="36px"
			height="36px"
		/>
	</CustomizerButton>
)
const FooterMobileSkeleton = () => {
	return (
		<FooterMobileContainer>
			<MobileButtonSkeleton />
			<FooterMobileSeparator role='presentation' />
			<MobileButtonSkeleton />
			<MobileButtonSkeleton />
			<MobileButtonSkeleton />
			<MobileButtonSkeleton />
			<FooterMobileSeparator role='presentation' />
			<Skeleton
				variant="circle"
				width="48px"
				height="48px"
			/>
		</FooterMobileContainer>
	)
}
const FooterDesktopSkeleton = () => {
	return (
		<FooterDesktopContainer>
			<FooterDesktopLeftActions>
				<Skeleton
					variant="rectangular"
					width="70px"
					height="70px"
				/>
				<Skeleton
					variant="rectangular"
					width="70px"
					height="70px"
				/>
			</FooterDesktopLeftActions>
			<FooterDesktopRightActions>
				<FooterRightActionButtons>
					<Skeleton
						variant="rectangular"
						width="280px"
						height="36px"
					/>
				</FooterRightActionButtons>
				<FooterRightCartInfo>
					<FooterDesktopRightActionsPriceInfoContainer>
						<PriceInfoSkeletonContainer>
							<Skeleton
								variant="text"
								width="300px"
								fontSize="1em"
							/>
							<br />
							<Skeleton
								variant="text"
								width="200px"
								fontSize="1em"
							/>
						</PriceInfoSkeletonContainer>
					</FooterDesktopRightActionsPriceInfoContainer>
					<Skeleton
						variant="rounded"
						width="140px"
						height="40px"
					/>
				</FooterRightCartInfo>
			</FooterDesktopRightActions>
		</FooterDesktopContainer>
	)
}

const FooterSkeleton = () => {
	const isMobile = useIsMobile();
	return (
		<FooterContainer>
			{isMobile && <FooterMobileSkeleton />}
			{!isMobile && <FooterDesktopSkeleton />}
		</FooterContainer>
	)
}

export default FooterSkeleton;
