import useToolsButtonsList from './useToolsButtonsList';

const useMainToolsMenu = () => {
	const { getToolsButtonsListFiltered } = useToolsButtonsList();

	const sections = [
		{
			key: 'main-tools',
			buttons: getToolsButtonsListFiltered({
				filterIn: { type: 'tool', key: ['design-elements'] },
				filterOut: { key: ['drafts'] },
			}),
		},
	];

	return { sections };
};

export default useMainToolsMenu;
