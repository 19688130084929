import styled, { css } from 'styled-components';
import { FooterButton, FooterOutlineButton, PrimaryButton } from '../../widgets/button';
import { MOBILE_BREAKPOINT, MOBILE_BREAKPOINT_PX, SMALLDESK_BREAKPOINT_PX } from '../../../shared/helpers';
import { CustomizerButton, Icon } from '../../globals';
import { darken, transparentize } from 'polished';

export const FooterDropDownBack = styled.div`
	position: relative;
	padding: 40px 35px 35px 15px;
	border: 1px solid ${(props) => props.theme.colors.borders};
	border-radius: ${(props) => props.theme.measures.borderRadius};
	background-color: ${(props) => transparentize(0.05, darken(0.02, props.theme.colors.backgroundMain))};
	min-width: 300px;

	@media (max-width: ${MOBILE_BREAKPOINT_PX}) {
		padding: 40px 35px 35px 25px;
		width: calc(100vw - 30px);
		max-width: 450px;
		transform: translateX(4px);
	}
`;

export const FooterDropdownClose = styled(Icon)`
	position: absolute;
	z-index: 2;
	right: 2px;
	top: 2px;
	padding: 11px 8px;
	width: 36px;
	height: 40px;
	color: ${(props) => props.theme.colors.iconMain};
	cursor: pointer;
	@media (hover) {
		&:hover {
			color: ${(props) => props.theme.colors.primary};
		}
	}
`;

export const FooterButtonExtra = styled(FooterOutlineButton)`
	@media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
		min-width: 75px;
	}
`;

export const FooterContainer = styled.footer<{ $hidden?: boolean }>`
	grid-area: footer;
	display: flex;
	justify-content: space-between;
	padding: 8px 30px 20px;
	transition: opacity 0.2s ease 0.1s;
	color: ${(props) => props.theme.colors.textFooter};
	background-color: ${(props) => props.theme.colors.backgroundFooter};
	border-radius: calc(${(props) => props.theme.measures.borderRadius} * 4)
		calc(${(props) => props.theme.measures.borderRadius} * 4) 0 0
		${(props) =>
	props.$hidden &&
	css`
				opacity: 0;
				pointer-events: none;
			`};
	@media screen and (max-width: ${SMALLDESK_BREAKPOINT_PX}) {
		padding-inline: 20px;
	}

	@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
		justify-content: space-between;
		align-items: center;
		padding-block: 6px;
		padding-inline: 5px 15px;
	}

	${FooterButtonExtra}, ${PrimaryButton} {
		min-width: 140px;
		@media (max-width: ${MOBILE_BREAKPOINT_PX}) {
			min-width: 90px;
		}
	}
`;

export const FooterMobileContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	${CustomizerButton} {
		background-color: transparent;
	}
	${PrimaryButton} {
		padding: 5px;
		width: 48px;
		min-width: 48px;
		height: 48px;
		overflow: hidden;
	}
`;

export const FooterMobileSeparator = styled.span`
	width: 1px;
	height: 48px;
	background-color: ${(props) => props.theme.colors.borders};
`;


export const FooterDesktopContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr minmax(max-content, 50%);
	justify-content: space-between;
	align-content: space-between;
	width: 100%;
	gap: 16px;
`;

export const FooterDesktopRightActions = styled.div`
	overflow: hidden;
	display: flex;
	flex-direction: column;
	gap: 8px;

	${FooterButton} {
		padding: 8px;
	}
`;
export const FooterRightActionButtons = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 8px;
`;
export const FooterRightCartInfo = styled.div`
	display: grid;
	grid-auto-columns: max-content;
	align-items: start;
	grid-auto-flow: column;
	margin-inline-start: auto;
	gap: 8px;
`;

export const FooterDesktopRightActionsPriceInfoContainer = styled.div`
	display: flex;
	align-items: center;
	max-width: 340px;
	margin-right: 5px;
	@media (max-width: ${SMALLDESK_BREAKPOINT_PX}) {
		max-width: 280px;
	}
`;

export const FooterDesktopLeftActions = styled.div`
	flex: 1 1;
	display: flex;
	align-items: center;
	padding-inline-end: 5px;
	gap: 10px;
	overflow: hidden;
`;

export const FooterDesktopVerticalDivider = styled.hr`
	display: block;
	height: 100%;
	width: 1px;
	border: 0;
	margin: 0;
	background: ${(props) => props.theme.colors.borders};
`;
