import {
	UiLayout,
	UiLayoutCustomizerContainer,
	UiLayoutRightPanelActionsContainer,
	UiLayoutRightPanelContainer,
	UiLayoutRightPanelPagesContainer,
	UiLayoutRightPanelToolsActionsContainer,
	UiLayoutRightPanelToolsContainer,
} from './uiLayout';
import MenuSkeleton from '../../menu/menuSkeleton';
import ToolsListSkeleton from '../../menu/toolsListSkeleton';
import FooterSkeleton from '../footer/footerSkeleton';
import { useIsMobile } from '../../../hooks/useLayoutCheck';
import CustomizerSkeleton from '../customizer/customizerSkeleton';
import Skeleton from '../../widgets/skeleton';

export const UiRightPanelSkeleton = () => {
	return (
		<UiLayoutRightPanelContainer>
			<UiLayoutRightPanelActionsContainer>
				<Skeleton
					variant="rectangular"
					width="24px"
					height="24px"
				/>
			</UiLayoutRightPanelActionsContainer>
			<UiLayoutRightPanelToolsActionsContainer />
			<UiLayoutRightPanelPagesContainer>
				<MenuSkeleton />
			</UiLayoutRightPanelPagesContainer>
			<UiLayoutRightPanelToolsContainer>
				<ToolsListSkeleton />
			</UiLayoutRightPanelToolsContainer>
		</UiLayoutRightPanelContainer>
	);
};

const UiSkeleton = () => {
	const isMobile = useIsMobile();
	return (
		<UiLayout>
			<UiLayoutCustomizerContainer>
				<CustomizerSkeleton />
			</UiLayoutCustomizerContainer>
			{!isMobile && <UiRightPanelSkeleton />}
			<FooterSkeleton />
		</UiLayout>
	)
}

export default UiSkeleton;
