import React, { FC } from 'react';
import { darken, transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { MOBILE_BREAKPOINT_PX } from '../shared/helpers';
import { MENU_BUTTON_HEIGHT } from './globals.consts';

export const Icon = styled.i<{ $rotate?: string; $size?: string }>`
	color: currentColor;
	fill: currentColor;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	${(props) =>
		props.$rotate &&
		css`
			transform: rotate(${props.$rotate});
		`}

	svg {
		fill: currentColor;
		width: 100%;
		height: 100%;
	}
	img {
		width: 70%;
		height: auto;
	}
`;

export const CustomizerButton = styled.div<{
	$active?: boolean;
	$disabled?: boolean;
	$enableHoverDisabled?: boolean;
	$bigIcon?: boolean;
}>`
	height: 40px;
	width: 40px;
	cursor: pointer;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: ${(props) => transparentize(0.15, props.theme.colors.backgroundCanvas)};

	${(props) =>
		props.$disabled &&
		!props.$enableHoverDisabled &&
		css`
			pointer-events: none;
		`}
	${(props) =>
		props.$disabled &&
		props.$enableHoverDisabled &&
		css`
			cursor: default;
		`}

	${Icon} {
		color: ${(props) =>
			props.$disabled
				? props.theme.colors.iconDisabled
				: props.$active
				? props.theme.colors.primary
				: props.theme.colors.iconMain};
		${(props) =>
			props.$bigIcon &&
			`
			transform: scale(1.2);
		`}
	}

	@media (hover) {
		&:hover {
			${Icon} {
				color: ${(props) => (props.$disabled ? props.theme.colors.iconDisabled : props.theme.colors.primary)};
			}
		}
	}

	@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
		height: 50px;
		width: 50px;
		color: ${(props) => props.theme.colors.iconMain};

		@media (hover) {
			&:hover {
				color: ${(props) => (props.$disabled ? props.theme.colors.iconDisabled : props.theme.colors.primary)};
			}
		}
		text-align: center;
		font-size: 11px;

		font-weight: 400;

		> div {
			width: 100%;
			padding-inline: 2px;

			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
`;

export const CustomizerButtonGroup = styled.div<{
	$vertical?: boolean;
	$variant?: 'flat' | 'outlined';
	$extraInlineMargin?: number;
}>`
	display: flex;
	flex-direction: row;
	${(props) =>
		props.$vertical &&
		css`
			flex-direction: column;
		`}
	max-width: 100%;
	transition: max-width 0.3s ease-in-out;
	border-radius: calc(${(props) => props.theme.measures.borderRadius} * 2);
	pointer-events: auto;
	overflow: hidden;
	${(props) =>
		props.$extraInlineMargin &&
		css`
			margin-inline-start: ${props.$extraInlineMargin}px;
		`}

	${(props) =>
		props.$variant === 'outlined' &&
		css`
			border: 1px solid ${props.theme.colors.borders};
		`}

	${CustomizerButton} {
		${(props) =>
			props.$variant === 'flat' &&
			css`
				background: none;
			`}
	}
`;

export const borderTextualInput = css<{ $invalid?: boolean }>`
	border: 1px solid ${(props) => props.theme.colors.borders};
	color: ${(props) => props.theme.colors.textMain};

	${(props) =>
		!props.$invalid &&
		css`
			&:focus {
				border-color: ${props.theme.colors.primary};
			}
		`};

	${(props) =>
		props.$invalid &&
		css`
			border-width: 1px;
			border-color: ${props.theme.colors.error};
			outline: 1px solid ${props.theme.colors.error};
		`}
`;
export const InputStyle = css<{ $invalid?: boolean }>`
	border-radius: calc(${(props) => props.theme.measures.borderRadius} * 2);
	color: ${(props) => props.theme.colors.textMain};
	background-color: ${(props) => props.theme.colors.backgroundMain};
	padding: 10px;
	width: 100%;
	min-height: 40px;
	font-size: 14px;
	line-height: 18px;
	outline: none;
	resize: none;
	transition: border-color 0.1s;

	&[type='checkbox'] {
		min-height: 0;
	}

	&[type='checkbox']:checked {
		border-color: ${(props) => props.theme.colors.primary};
		position: relative;

		&::before {
			content: ' ';
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			inset: 0;
			background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="20 6 9 17 4 12"/></svg>');
			background-color: ${(props) => props.theme.colors.primary};
		}
	}

	${borderTextualInput}
`;

// Outlined styled input
export const Input = styled.input<{ $invalid?: boolean; $fullWidth?: boolean }>`
	${InputStyle}
	&:disabled {
		color: ${(props) => props.theme.colors.textDisabled};
	}
	${(props) =>
		props.$fullWidth &&
		css`
			width: 100%;
		`}
`;

export const UnderlinedInputStyle = css<{ $invalid?: boolean }>`
	padding: 4px;
	width: 100%;
	min-height: 18px;
	font-size: 14px;
	line-height: 16px;
	outline: none;
	resize: none;
	transition: border-color 0.1s;

	${borderTextualInput};

	border-top: 0;
	border-left: 0;
	border-right: 0;
	background: inherit;
`;
export const UnderlinedInput = styled.input<{ $invalid?: boolean }>`
	${UnderlinedInputStyle}
`;

export const TextArea = styled.textarea<{ rows?: string; $invalid?: boolean; $resize?: string }>`
	${borderTextualInput};
	min-height: 40px;
	max-height: 160px;
	padding: 10px;
	width: 100%;
	font-size: 14px;
	outline: none;
	resize: none;
	border-radius: calc(${(props) => props.theme.measures.borderRadius}*4);
	border-color: ${(props) => props.theme.colors.borders};
	background-color: ${(props) => props.theme.colors.backgroundMain};
	transition: border-color 0.1s;
	${(props) =>
		props.$resize &&
		css`
			resize: ${props.$resize};
		`}
`;

const FormControlContainer = styled.div<{ $widthPercentage?: number; $error?: boolean }>`
	display: flex;
	flex-direction: column;
	width: ${(props) => (props.$widthPercentage ? props.$widthPercentage : 100)}%;
	${(props) =>
		props.$error &&
		css`
			color: ${props.theme.colors.error};
		`}
`;

const FormControlLabel = styled.label`
	font: ${(props) => props.theme.fonts.largeBody};
	margin-bottom: 5px;
`;

const FormControlHelperText = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 12px;
	font-weight: 500;
	line-height: 20px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	${Icon} {
		display: inline-flex;
		width: 20px;
		height: 20px;
		margin-right: 8px;
	}
`;

export type FormControlProps = {
	label?: React.ReactNode;
	children: React.ReactNode;
	widthPercentage?: number;
	helperText?: React.ReactNode;
	helperTextIcon?: React.ReactNode;
	error?: boolean;
	className?: string;
};
export const FormControl: FC<FormControlProps> = ({
	label,
	children,
	widthPercentage,
	className,
	helperText,
	helperTextIcon,
	error,
}) => {
	return (
		<FormControlContainer $widthPercentage={widthPercentage} $error={error} className={className}>
			{label && <FormControlLabel>{label}</FormControlLabel>}
			{children}
			{helperText && (
				<FormControlHelperText>
					{helperTextIcon && <Icon>{helperTextIcon}</Icon>}
					{helperText}
				</FormControlHelperText>
			)}
		</FormControlContainer>
	);
};

export const SettingsFormControl = styled(FormControl)`
	justify-content: space-between;
	flex-direction: row;
	gap: 30px;
	padding: 8px 0;
	label {
		flex: 0 0 40%;
		font-size: 13px;
		padding-block-start: 10px;
		padding-inline-start: 24px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	& > div {
		max-width: 220px;
		flex-grow: 1;
	}
	@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
		& > div {
			max-width: 100% !important;
		}
		flex-direction: column;
		gap: 0;
		label {
			padding-block-start: 0;
			padding-inline-start: 0;
		}
	}
`;

const MenuButtonContainer = styled.button<{ $highlight?: boolean }>`
	font-weight: 400;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-align: start;
	min-width: 150px;
	height: ${MENU_BUTTON_HEIGHT}px;
	padding: 12px 16px 12px 16px;

	color: ${(props) => props.theme.colors.iconMain};
	background-color: ${(props) => transparentize(0.1, darken(0.035, props.theme.colors.backgroundMain))};
	border: 0 none;
	border-radius: calc(${(props) => props.theme.measures.borderRadius} * 2);
	${(props) =>
		props.$highlight &&
		css`
			font-weight: bold;
		`};

	@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
		padding-inline: 14px;
	}

	${Icon} {
		margin-inline-end: 14px;
		transform: scale(1.2);
	}

	${(props) =>
		!props.disabled &&
		css`
			cursor: pointer;

			@media (hover) {
				&:hover {
					color: ${props.theme.colors.primary};
					${Icon} {
						color: ${props.theme.colors.primary};
					}
				}
			}
		`}

	${(props) =>
		props.disabled &&
		css`
			color: ${props.theme.colors.textDisabled};
			${Icon} {
				color: ${props.theme.colors.iconDisabled};
			}
		`};
	@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
		font-size: 13px;
		min-width: 135px;

		${Icon}:first-child {
			margin-inline-end: 10px;
			transform: scale(1.1);
		}
	}
`;

export const MenuButtonLabel = styled.span`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
`;

export const ButtonRectangle = styled.button<{ $buttonsSize?: number }>`
	position: relative;
	display: flex;
	flex-grow: 1;
	min-width: ${(props) =>
		props.$buttonsSize && props.$buttonsSize > 1
			? `calc( (100% + 8px * (1 - ${props.$buttonsSize}))/ ${props.$buttonsSize} )`
			: '20%'};
	max-width: ${(props) =>
		props.$buttonsSize && props.$buttonsSize > 1
			? `calc( (100% + 8px * (1 - ${props.$buttonsSize}))/ ${props.$buttonsSize} )`
			: 'auto'};
	justify-content: center;
	align-items: center;
	padding: 8px 4px;
	border: 0;
	color: ${(props) => props.theme.colors.textMain};
	border-radius: ${(props) => props.theme.measures.borderRadius};
	background-color: ${(props) => props.theme.colors.backgroundSecondary};
	min-height: 48px;

	${Icon} {
		width: 20px;
		min-width: 20px;
		color: ${(props) => props.theme.colors.iconMain};
	}

	span {
		padding-inline: 4px;
		font-size: 12px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	@media (hover) {
		&:hover {
			cursor: pointer;
			color: ${(props) => props.theme.colors.primary};

			${Icon} {
				color: ${(props) => props.theme.colors.primary};
			}
		}
	}
	@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
		flex: 1 1 25%;
		flex-direction: column;
		padding: 8px 8px 28px;
		span {
			position: absolute;
			width: 100%;
			left: 0;
			bottom: 10px;
			padding-inline: 8px;
		}
	}
`;
export const ButtonRectangleIcon = styled(ButtonRectangle)`
	padding-bottom: 8px;
`;

export const IconRoundButtonStyles = css`
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 50%;
	z-index: 3;
	padding: 8px;
	border: 2px solid transparent;

	cursor: pointer;
	pointer-events: auto;
	transition: all 0.3s ease-in-out 0s;

	& svg {
		transition: transform 0.3s ease-in-out 0s;
	}

	@media (hover) {
		&:hover {
			background-color: ${(props) => props.theme.colors.accentState};
			border-color: ${(props) => props.theme.colors.accentState};
			color: ${(props) => props.theme.colors.textOverAccent};
		}
	}
`;

interface MenuButtonProps {
	onClick: React.MouseEventHandler<HTMLButtonElement>;
	highlight?: boolean;
	textonly?: boolean;
	disabled?: boolean;
	leftIcon?: React.ReactNode;
	children?: React.ReactNode;
}

export const MenuButton: FC<MenuButtonProps> = ({ onClick, highlight, leftIcon, disabled, children }) => {
	return (
		<MenuButtonContainer onClick={onClick} $highlight={highlight} disabled={disabled}>
			{leftIcon && <Icon>{leftIcon}</Icon>}
			{children}
		</MenuButtonContainer>
	);
};

export const MenuButtonGroup = styled.div`
	display: flex;
	gap: 8px;
	button {
		flex: 1 1;
	}
`;

const ToolButtonContainer = styled(MenuButtonContainer)``;
export const ToolButton: FC<MenuButtonProps> = ({ onClick, highlight, leftIcon, disabled, children }) => {
	return (
		<ToolButtonContainer onClick={onClick} $highlight={highlight} disabled={disabled}>
			{leftIcon && <Icon>{leftIcon}</Icon>}
			{children}
		</ToolButtonContainer>
	);
};

export const Stack = styled.div<{
	$horizontal?: boolean;
	$gap?: number;
	$expand?: boolean;
	$marginTop?: number;
	$marginBottom?: number;
	$marginLeft?: number;
	$marginRight?: number;
}>`
	display: flex;
	flex-direction: ${(props) => (props.$horizontal ? 'row' : 'column')};
	justify-content: flex-start;
	gap: ${(props) => props.$gap ?? 20}px;
	margin-top: ${(props) => props.$marginTop ?? 0}px;
	margin-bottom: ${(props) => props.$marginBottom ?? 0}px;
	margin-left: ${(props) => props.$marginLeft ?? 0}px;
	margin-right: ${(props) => props.$marginRight ?? 0}px;
	min-width: 0;

	${(props) =>
		!props.$horizontal &&
		props.$expand &&
		`
            flex: 1;
            min-height: 0;
        `}

	${(props) =>
		props.$horizontal &&
		`
        ${props.$expand && `width: 100%;`};
        justify-content: stretch;
    `}
`;

export const Columns = styled.div<{ $gap?: number }>`
	display: flex;
	gap: ${(props) => props.$gap ?? 20}px;

	> * {
		flex: 1;
	}
`;

export const HR = styled.hr`
	border: 1px solid #eee;
	margin: 20px 0;
`;

export const Truncate = () => css`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	min-width: 0;
`;

export const fontStack = css`
	font-family: -apple-system, BlinkMacSystemFont, 'Nunito', 'Helvetica', 'Segoe', sans-serif;
`;

export const P = styled.p`
	${fontStack};
	font-weight: 400;
	font-size: 14px;
	line-height: 1.4;
	margin: 0;
	padding: 0;
`;

export const MobileFontPreview = styled.div`
	width: 100%;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-block: 8px;
	padding-inline: 10px 4px;
	border: 1px solid ${(props) => props.theme.colors.borders};
	border-radius: ${(props) => props.theme.measures.borderRadius};
	background-color: ${(props) => props.theme.colors.backgroundMain};
	cursor: pointer;

	img {
		pointer-events: none;
		width: calc(100% - 34px);
		height: 100%;
		object-fit: contain;
		object-position: 0 center;
	}

	${Icon} {
		width: 20px;
		height: 20px;
		flex-shrink: 0;
		color: ${(props) => props.theme.colors.iconMain};
		pointer-events: none;
	}

	@media (hover) {
		&:hover {
			img {
				opacity: 0.75;
			}

			${Icon} {
				color: ${(props) => props.theme.colors.primary};
			}
		}
	}
`;

export const SegmentTitle = styled.h2`
	margin: 0;
	font: ${(props) => props.theme.fonts.large100bold};
	color: ${(props) => props.theme.colors.textMain};
`;
