import { useMemo } from 'react';
import { useZakekeTranslations } from '@zakeke/zakeke-customizer-react';

import { ImageProvider } from '../components/interfaces';
import useClipartsCategories from './imagesProviders/cliparts/useClipartsCategories';
import useClipartsCreateImage from './imagesProviders/cliparts/useClipartsCreateImage';
import useClipartsImages from './imagesProviders/cliparts/useClipartsImages';
import useFacebookCategories from './imagesProviders/facebook/useFacebookCategories';
import useFacebookCreateImages from './imagesProviders/facebook/useFacebookCreateImages';
import useFacebookImages from './imagesProviders/facebook/useFacebookImages';
import usePremiumPhotosCategories from './imagesProviders/premiumPhotos/usePremiumPhotosCategories';
import usePremiumPhotosCreateImage from './imagesProviders/premiumPhotos/usePremiumPhotosCreateImage';
import usePremiumPhotosImages from './imagesProviders/premiumPhotos/usePremiumPhotosImages';
import useSellerGalleryCategories from './imagesProviders/sellerGallery/useSellerGalleryCategories';
import useSellerGalleryCreateImage from './imagesProviders/sellerGallery/useSellerGalleryCreateImage';
import useSellerGalleryImages from './imagesProviders/sellerGallery/useSellerGalleryImages';
import useFreshRef from './useFreshRef';
import useToolsNames from './useToolsNames';

const clipartsProvider: ImageProvider = {
	title: '',
	name: 'cliparts',
	useCategories: useClipartsCategories,
	useImages: useClipartsImages,
	useCreateImage: useClipartsCreateImage,
	hasSearchByName: true,
};

const facebookProvider: ImageProvider = {
	title: '',
	name: 'facebook',
	useCategories: useFacebookCategories,
	useImages: useFacebookImages,
	useCreateImage: useFacebookCreateImages,
	hasSearchByName: false,
};

const sellerGalleryProvider: ImageProvider = {
	title: '',
	name: 'sellergallery',
	useCategories: useSellerGalleryCategories,
	useImages: useSellerGalleryImages,
	useCreateImage: useSellerGalleryCreateImage,
	hasSearchByName: false,
};

const premiumPhotosProvider: ImageProvider = {
	title: '',
	name: 'premiumphotos',
	useCategories: usePremiumPhotosCategories,
	useImages: usePremiumPhotosImages,
	useCreateImage: usePremiumPhotosCreateImage,
	hasSearchByName: true,
};

export type ImageProviders = {
	clipartsProvider: ImageProvider;
	facebookProvider: ImageProvider;
	sellerGalleryProvider: ImageProvider;
	premiumPhotosProvider: ImageProvider;
};

type providerFunctionNames = 'useCategories' | 'useImages' | 'useCreateImage';
type HandleProviderError<T extends providerFunctionNames> = (e: {
	error: unknown;
	providerName: string;
}) => never | ReturnType<ImageProvider[T]>;

export const useImageProvider = ({
	// handleCategoriesError,
	handleImagesError,
}: // handleCreateImagesError,
{
	// handleCategoriesError?: HandleProviderError<'useCategories'>,
	handleImagesError?: HandleProviderError<'useImages'>;
	// handleCreateImagesError?: HandleProviderError<'useCreateImage'>,
} = {}): ImageProviders => {
	const handleImagesErrorCallback = useFreshRef(handleImagesError);
	const toolNames = useToolsNames();

	const { T } = useZakekeTranslations();
	return useMemo(() => {
		const providerErrorDecorator = (p: ImageProvider) => {
			const catchError = <T extends providerFunctionNames>(
				p: ImageProvider,
				callbackName: T,
				handleProviderError?: HandleProviderError<T>,
			) => {
				const providerName = p.name;
				const cb = p[callbackName];

				return (...args: Parameters<typeof cb>): ReturnType<typeof cb> => {
					try {
						return (cb as any)(...args);
					} catch (error) {
						// in this case handling supanse promise
						if (error instanceof Promise) {
							throw error;
							// in this case handling error
						} else if (handleProviderError) {
							return handleProviderError({ error, providerName });
						}
						// in this case error without handling
						throw error;
					}
				};
			};

			return {
				...p,
				// useCategories: catchError<'useCategories'>(p, 'useCategories', handleCategoriesError),
				useImages: catchError<'useImages'>(p, 'useImages', handleImagesErrorCallback.current),
				// useCreateImage: catchError<'useCreateImage'>(p, 'useCreateImage', handleCreateImagesError),
			};
		};

		return {
			clipartsProvider: {
				...providerErrorDecorator(clipartsProvider),
				title: toolNames.cliparts.longText,
			},
			facebookProvider: {
				...providerErrorDecorator(facebookProvider),
				title: T._('Facebook'),
			},
			sellerGalleryProvider: {
				...providerErrorDecorator(sellerGalleryProvider),
				title: toolNames.cliparts.longText,
			},
			premiumPhotosProvider: {
				...providerErrorDecorator(premiumPhotosProvider),
				title: toolNames.cliparts.longText,
			},
		};
	}, [T, handleImagesErrorCallback, toolNames]);
};
