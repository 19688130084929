import { useMemo } from 'react';
import { isNumber } from 'lodash';
import { useZakekePremiumPhotosImagesPaginated } from '@zakeke/zakeke-customizer-react';

import { UseImages } from '../../../components/interfaces';

export type PremiumPhotoFilters = {
	dp_day_time: string | null;
	dp_season: string | null;
	dp_country: string | null;
	dp_search_render: string | null;
	dp_search_advanced: string | null;
	dp_non_stocky: string | null;
	dp_search_query: string | null;
	dp_search_color: string | null;
	dp_search_sort: number | null;
	dp_search_orientation: string | null;
	dp_search_categories: string | null;
	dp_search_nudity?: boolean | null;
	dp_search_editorial?: boolean | null;
	dp_country_excluded: string | null;
	dp_search_max_height?: number | null;
	dp_search_max_width?: number | null;
	dp_search_dimension_units: string | null;
	dp_search_gender: string | null;
	dp_search_people_only?: boolean | null;
	dp_search_anonymous?: boolean | null;
	dp_search_age: string | null;
	dp_search_race: string | null;
	dp_search_quantity?: number | null;
	dp_exclude_keywords: string | null;
	dp_search_isolated?: number | null;
	dp_illustration?: number | null;
	offset: number | null;
	maxresults: number | null;
};

const createBaseQuery = (
	categoryId: string | null,
	filter: string,
	maxResults?: number,
	query?: Partial<PremiumPhotoFilters>,
) => ({
	dp_day_time: null,
	dp_season: null,
	dp_country: null,
	dp_non_stocky: null,
	dp_search_render: null,
	dp_search_advanced: null,
	dp_search_categories: categoryId,
	dp_search_query: filter || categoryId || '',
	dp_search_color: null,
	dp_search_orientation: null,
	dp_search_editorial: null,
	dp_country_excluded: null,
	dp_search_max_height: null,
	dp_search_max_width: null,
	dp_search_dimension_units: null,
	dp_search_isolated: null,
	dp_search_gender: null,
	dp_search_people_only: null,
	dp_search_anonymous: null,
	dp_search_age: null,
	dp_search_race: null,
	dp_search_sort: null,
	dp_search_quantity: null,
	dp_exclude_keywords: null,
	offset: 0,
	maxresults: maxResults ?? 24,
	...query,
});

const usePremiumPhotosImages: UseImages = (
	categoryId: string | number | null,
	filter: string,
	query?: Partial<PremiumPhotoFilters>,
) => {
	const cat = useMemo(() => {
		if (isNumber(categoryId)) {
			return categoryId.toString();
		}
		return categoryId;
	}, [categoryId]);

	const premiumQuery = useMemo(() => createBaseQuery(cat, filter, 16, query ?? {}), [cat, filter, query]);
	const images = useZakekePremiumPhotosImagesPaginated(premiumQuery, 16);

	return useMemo(
		() => ({
			...images,
			images: (images.data ?? []).map((image: any) => ({
				id: image.id.toString(),
				previewUrl: image.thumb_huge,
				url: image.non_wm_thumb,
				name: image.id.toString(),
				width: image.width,
				height: image.height,
			})),
		}),
		[images],
	);
};

export default usePremiumPhotosImages;
