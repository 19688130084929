import { useZakekeImageCategories, useZakekeTranslations } from '@zakeke/zakeke-customizer-react';
import { ImageGalleryMacroCategory } from '../../../components/widgets/imagesGallery';
import { sortBy } from 'lodash';

/**
 * Hook to get the cliparts categories
 */
const useClipartsCategories = () => {
	const macroCategories = useZakekeImageCategories();
	const { T } = useZakekeTranslations();

	let categories: ImageGalleryMacroCategory[] = [{ value: null, label: T._('Browse categories') }];

	categories = categories.concat(
		macroCategories
			.sort((a, b) => a.macroCategoryPosition - b.macroCategoryPosition)
			.filter((x) => x.hasImages)
			.map((x) => ({
				value: x.macroCategoryID,
				options: sortBy(
					x.categories
						.filter((x) => x.hasImages)
						.map((y) => ({
							value: y.categoryID,
							label: y.name,
						})),
					(category) => category.label,
				),
				label: x.name,
			})),
	);

	return categories;
};

export default useClipartsCategories;
