import styled, { css } from 'styled-components';
import React, { FC } from 'react';

import { MOBILE_BREAKPOINT_PX } from '../../../shared/helpers';
import Spinner from '../spinner';
import LoadingText, { LoadingTextConfig } from './loadingText';

const LoadingOverlay = styled.div<{ $darkBack?: boolean; $forceDarkBackText?: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	text-wrap-style: balance;
	z-index: 30;
	transition: background-color 0.3s ease-in;
	${(props) =>
		props.$darkBack
			? props.$forceDarkBackText
				? css`
						background-color: rgba(0, 0, 0, 0.65);
						color: #ffffff;
				  `
				: css`
						background-color: rgba(0, 0, 0, 0.15);
				  `
			: css`
					background-color: rgba(0, 0, 0, 0.025);
			  `};
	@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
		${(props) =>
			props.$darkBack
				? css`
						background-color: rgba(0, 0, 0, 0.75);
				  `
				: css`
						background-color: rgba(0, 0, 0, 0.5);
				  `}
	}
`;

type LoadingProps = {
	darkBack?: boolean;
	forceDarkBackText?: boolean;
	text?: LoadingTextConfig;
};
const Loading: FC<LoadingProps> = ({ darkBack = false, forceDarkBackText = false, text }) => {
	return (
		<LoadingOverlay $darkBack={darkBack} $forceDarkBackText={forceDarkBackText}>
			<Spinner forceDarkBackText={forceDarkBackText} />
			{text && <LoadingText forceDarkBackText={forceDarkBackText}>{text}</LoadingText>}
		</LoadingOverlay>
	);
};

export default Loading;
