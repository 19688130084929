import {
	ColorMapping,
	Design,
	Image as ZakekeImage,
	NameNumberSelection,
	useZakekeEngraving,
	useZakekeHelpers,
} from '@zakeke/zakeke-customizer-react';
import { Map } from 'immutable';
import { cloneDeep, differenceWith, isEqual, toPairs, remove } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import { atom, useAtomValue } from 'jotai';
import { atomFamily, selectAtom, useAtomCallback } from 'jotai/utils';

import {
	AllElements,
	CustomizerElement,
	ImageElement,
	ImageProvider,
	isImageElement,
	isShapeElement,
	isTextArtElement,
	isTextElement,
	Point,
	ShapeElement,
	TextArtElement,
	TextElement,
} from '../components/interfaces';
import { bringToFront, moveItemToAbove, moveItemToBelow, sendToBack } from './utils';

import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { useUndoRedoCommit } from '../hooks/useUndoRedo';
import { differenceIndex, isMobile } from '../shared/helpers';
import { getNewZIndex } from './utils';
import { DesignItemTagType } from './interfaces/designItemTagType';
import { SideOptions } from './interfaces/sideOptions';
import useSetAtomWithPrev from '../hooks/useSetAtomWithPrev';
import atomWithListeners, { AtomWithListnersCallback } from './jotai/atomWithListners';
import { atomWithStore } from 'jotai-zustand';

export type CloseClickEvent = {
	setPreventDefaultCloseBehaviour: (b: boolean) => void;
};

export type MobileTextartEditPageView =
	| 'textart-edit'
	| 'textart-content'
	| 'textart-font'
	| 'textart-glyphs'
	| 'textart-style'
	| 'textart-stroke-width'
	| 'textart-type'
	| 'textart-parameters'
	| 'textart-fill-color'
	| 'textart-stroke-color';


type NonePage = { page: 'none' };
type DesignElementsPage = { page: 'design-elements' };
type AddPage = { page: 'add' };
type UploadImagePage = {
	page: 'upload-image';
	isReplacingImageMode?: boolean;
	isFillShapeMode?: boolean;
	isBackgroundEditMode?: boolean;
	collageBoxId?: number | null;
	onClose?: (e: CloseClickEvent) => void;
};
type YourImagesPage = {
	page: 'your-images';
	isReplacingImageMode?: boolean;
	isFillShapeMode?: boolean;
	isBackgroundEditMode?: boolean;
	collageBoxId?: number | null;
	expanded?: boolean;
	onClose?: (e: CloseClickEvent) => void;
};
type SourceImageSelectorPage = {
	page: 'source-image-selector';
	title: string;
	isReplacingImageMode?: boolean;
	isFillShapeMode?: boolean;
	isBackgroundEditMode?: boolean;
	collageBoxId?: number | null;
	onClose?: (e: CloseClickEvent) => void;
};
export type EditImagePage = { page: 'edit-image'; guid: string };
type RecolorImagePage = {
	page: 'recolor-image';
	sideId: number;
	printTypeId: number;
	image: ZakekeImage;
	initialColorMappings: ColorMapping[];
	onContinue: (colorMappings: ColorMapping[]) => void;
	onClose?: () => void;
};
type EditTextPage = { page: 'edit-text'; guid?: string };
type EditTextArtPage = { page: 'edit-text-art'; guid?: string };
type EditShapePage = { page: 'edit-shape'; guid?: string };
type ImagesPage = {
	page: 'images';
	imageProvider: ImageProvider;
	initialCategoryId?: number | null;
	isReplacingImageMode?: boolean;
	isFillShapeMode?: boolean;
	isBackgroundEditMode?: boolean;
	collageBoxId?: number | null;
	onClose?: (e: CloseClickEvent) => void;
	expanded?: boolean;
	onExpand?: () => void;
};
type PremiumImagesPage = {
	page: 'premium-images';
	imageProvider: ImageProvider;
	initialCategoryId?: number | null;
	isReplacingImageMode?: boolean;
	isFillShapeMode?: boolean;
	isBackgroundEditMode?: boolean;
	collageBoxId?: number | null;
	onClose?: (e: CloseClickEvent) => void;
};
type TemplatesPage = { page: 'templates'; expanded?: boolean };
type DraftsPage = { page: 'drafts'; expanded?: boolean };
type CollagesPage = { page: 'collages'; expanded?: boolean };
type CollageLayoutsPage = { page: 'collage-layout' };
type ShapesPage = { page: 'shapes'; expanded?: boolean };
type BackgroundEditingPage = { page: 'background-editing' };
type ImageMaskSelection = { page: 'mask-selector'; guid: string; onClose?: () => void; expanded?: boolean };
type SwitchVariantPage = { page: 'switch-variant' };
type SidesPage = { page: 'show-sides' };

type MobileAddPage = { page: 'add'; onClose?: () => void };
type MobileTextEditPage = { page: 'text-edit'; guid: string };
type MobileTextAddPage = { page: 'text-add'; onClose?: () => void };
export type MobileImageEditPage = { page: 'image-edit'; guid: string };
type MobileShapeEditPage = { page: 'shape-edit'; guid: string };
type MobileShapeEditUploadImagePage = { page: 'shape-edit-upload-image'; guid: string };
type MobileTextartEditPage = { page: 'textart-edit'; guid: string; view?: MobileTextartEditPageView; };
type MobileTextartAddPage = { page: 'textart-add'; onClose?: () => void };
type MobileBackgroundEditingPage = { page: 'background-editing' };
type MobileCollageEditPage = { page: 'collage-edit' };
type PredesignedTemplateEditPage = { page: 'predesigned-template-edit' };
type MobilePrintingMethods = { page: 'print-methods' };
type MobileSourceSelectorSection = {
	page: 'source-image-selector';
	title?: string;
	isReplacingImageMode?: boolean;
	isFillShapeMode?: boolean;
	isBackgroundEditMode?: boolean;
	collageBoxId?: number | null;
	onClose?: (e: CloseClickEvent) => void;
};
type MobileCustomPage = {
	page: 'custom';
	content: React.ReactNode;
	title?: string;
	onClose?: () => void;
};

export type MobileMenuPageType =
	| NonePage
	| MobileAddPage
	| MobileTextEditPage
	| MobileTextAddPage
	| MobileImageEditPage
	| MobileShapeEditPage
	| MobileShapeEditUploadImagePage
	| MobileTextartEditPage
	| MobileTextartAddPage
	| MobileBackgroundEditingPage
	| MobileCollageEditPage
	| PredesignedTemplateEditPage
	| MobileSourceSelectorSection
	| MobilePrintingMethods
	| MobileCustomPage;

export type MenuPageType =
	| NonePage
	| CollagesPage
	| CollageLayoutsPage
	| DesignElementsPage
	| AddPage
	| UploadImagePage
	| YourImagesPage
	| RecolorImagePage
	| EditImagePage
	| EditTextPage
	| EditTextArtPage
	| EditShapePage
	| ImagesPage
	| PremiumImagesPage
	| TemplatesPage
	| DraftsPage
	| SourceImageSelectorPage
	| ShapesPage
	| BackgroundEditingPage
	| ImageMaskSelection
	| SwitchVariantPage
	| SidesPage;

export type HighlightSidesWarning =
	| 'customizer-warnings-quality'
	| 'customizer-warnings-clipped'
	| 'empty-text-and-text-art-items'
	| 'mandatory-to-edit'
	| 'profanity';

export type InvalidItem = {
	guid: string;
	reason: 'profanity';
};

export type SetSelectedItemGuidOptions = {
	preventPageChange?: boolean;
};
export type Pivot = Point & {
	itemGuid: string;
};
export type SideAreaBackgrounds = Map<number, Map<number, { fillColor: string | null; imageItemGuid: string | null }>>;
export interface AppStore {
	selectedVariantId: number;
	selectedSideId: number;
	isFullscreenEnabled: boolean;
	isBootCompleted: boolean;
	setIsBootCompleted: (isBootCompleted: boolean) => void;

	changedSide: Set<number>;
	setChangedSide: (id: number) => void;
	changedItemId: Set<string>;
	setChangedItemId: (id: string) => void;

	sidesEditedReady: Set<number>;
	setSidesEditedReady: (id: number) => void;

	isAddToCartClicked: boolean;
	setIsAddToCartClicked: (isAddToCartClicked: boolean) => void;

	forceResetEnabled: boolean;
	setForceResetEnabled: (forceResetEnabled: boolean) => void;

	isSaveDraftClicked: boolean;
	setIsSaveDraftClicked: (isSaveDraftClicked: boolean) => void;

	isFullscreenActive: boolean;
	setIsFullscreenActive: (isFullscreenActive: boolean) => void;

	isLoading: boolean;
	setIsLoading: (isLoading: boolean) => void;

	isFirstPrintMethodChange: boolean;
	setIsFirstPrintMethodChange: (isFirstPrintMethodChange: boolean) => void;

	setSelectedVariantId: (id: number) => void;
	setSelectedSideId: (id: number) => void;
	setVariantAndSide: (variantId: number, sideId: number) => void;

	highlightSidesWarning: HighlightSidesWarning | null;
	setHighlightSidesWarning: (warning: HighlightSidesWarning | null) => void;

	invalidItems: InvalidItem[];
	setInvalidItems: (items: InvalidItem[]) => void;

	sidesPrintMethods: Map<number, number>;
	setSidesPrintMethods: (sidesPrintTypes: Map<number, number>) => void;

	sidesRotations: Map<number, number>;
	preventAdaptForSideRotation: boolean;
	setSidesRotations: (sidesRotations: Map<number, number>) => void;
	setPreventAdaptForSideRotation: (preventAdaptForSideRotation: boolean) => void;
	rotateSide: (sideId: number, angle: number) => void;

	sidesCollages: Map<number, number | null>;
	setSidesCollages: (sidesCollages: Map<number, number>) => void;
	setCollageForSide: (sideId: number, collageId: number | null) => void;

	resizableOptions: Map<number, Map<number, SideOptions>>; // variantid => sideid => options
	setSideOptions: (resizableOptions: Map<number, Map<number, SideOptions>>) => void;
	setResizableOptionsForSide: (variantId: number, sideId: number, options: SideOptions) => void;

	// mask-image
	/* maskMode: MaskedImageSelectionMode;
	setMaskMode: (mode: MaskedImageSelectionMode) => void; */

	selectedItemGuid: string | null;
	setSelectedItemGuid: (guid: string | null, options?: SetSelectedItemGuidOptions) => void;

	lastActiveAreaPerSide: Map<number, number>;
	setLastActiveAreaPerSide: (sideId: number, areaId: number) => void;

	lastAddedGuid: string | null;
	setLastAddedGuid: (guid: string | null) => void;

	newItemGuids: Set<string>;
	setNewItemGuids: (id: string) => void;
	removeNewItemGuids: (id: string) => void;

	mobileMenuPage: MobileMenuPageType;
	setMobileMenuPage: (page: MobileMenuPageType) => void;

	preventPageChangeOnItemChange: boolean;
	menuPageHistory: MenuPageType[];
	menuPageOnExit: null | (() => void);
	menuPage: MenuPageType;
	/**
	 * Change the current page and push a new entry in the history if updateBack is not false.
	 * This is like a push/replace in a router history.
	 */
	setMenuPage: (page: MenuPageType, updateBack?: boolean, onExit?: null | (() => void)) => void;
	openEditMenuPageForItem: (item: CustomizerElement, updateBack?: boolean) => void;
	openEditMobileMenuPageForItem: (item: CustomizerElement) => void;
	goBackMenuPage: () => void;
	clearMenuHistory: (newFirstPage?: MenuPageType) => void;
	removeStaleEditItemFromHistory: (currentGuid?: string) => void;

	zoom: number | null;
	setZoom: (zoom: number | null) => void;

	priceFormatter: Intl.NumberFormat;
	setPriceFormatter: (priceFormatter: Intl.NumberFormat) => void;

	isPreview3DOpened: boolean;
	preview3DMode: 'small' | 'swap' | 'full';
	setIsPreview3DOpened: (isPreview3DOpened: boolean) => void;
	setPreview3DMode: (preview3DMode: 'small' | 'swap' | 'full') => void;

	dialogs: [string, React.ReactElement][];
	addDialog: (id: string, dialog: React.ReactElement) => void;
	removeDialog: (id: string) => void;

	photoEditors: [string, React.ReactElement][];
	showPhotoEditor: (id: string, dialog: React.ReactElement) => void;
	removePhotoEditor: (id: string) => void;

	vto: [string, React.ReactElement] | null;
	showVto: (id: string, dialog: React.ReactElement) => void;
	removeVto: (id: string) => void;

	dropdowns: [string, React.ReactElement][];
	showDropdown: (id: string, dropdown: React.ReactElement) => void;
	removeDropdown: (id: string) => void;

	currentTemplate: Design | null;
	setCurrentTemplate: (template: Design | null) => void;

	currentDraft: { id: string; name: string; tags: string[] } | null;
	setCurrentDraft: (draft: { id: string; name: string; tags: string[] } | null) => void;

	editDesignId: string | null;
	setEditDesignId: (designId: string | null) => void;

	recentColors: string[];
	addRecentColor: (color: string) => void;

	preventCollageElementsReadapt: boolean;
	setPreventCollageElementsReadapt: (preventCollageElementsReadapt: boolean) => void;

	currentPivot: Pivot | null;
	setCurrentPivot: (currentPivot: Pivot | null) => void;

	nameNumberSelection: NameNumberSelection[];
	setNameNumberSelection: (nameNumberSelection: NameNumberSelection[]) => void;

	sideAreaBackgrounds: SideAreaBackgrounds;
	setSideAreaBackgrounds: (
		sideId: number,
		areaId: number,
		fillColor: string | null,
		imageItemGuid: string | null,
	) => void;
	removeSideAreaBackgrounds: () => void;

	selectionData: { width: number; height: number; positionX: number; positionY: number; rotation: number } | null;
	setSelectionData: (
		selectionData: { width: number; height: number; positionX: number; positionY: number; rotation: number } | null,
	) => void;

	recentFonts: string[];
	setRecentFonts: (f: string[]) => void;
}

export const useAppStore = createWithEqualityFn<AppStore>(
	(set, get) => ({
		selectedVariantId: 0,
		selectedSideId: 0,
		isBootCompleted: false,
		setIsBootCompleted: (isBootCompleted) => set({ isBootCompleted }),
		isAddToCartClicked: false,
		setIsAddToCartClicked: (isAddToCartClicked) => set({ isAddToCartClicked }),
		forceResetEnabled: false,
		setForceResetEnabled: (forceResetEnabled) => set({ forceResetEnabled }),
		isSaveDraftClicked: false,
		setIsSaveDraftClicked: (isSaveDraftClicked) => set({ isSaveDraftClicked }),

		isFullscreenEnabled: document.fullscreenEnabled,

		isFullscreenActive: false,
		setIsFullscreenActive: (isFullscreenActive) => set({ isFullscreenActive }),

		isLoading: false,
		setIsLoading: (isLoading) => set({ isLoading }),

		isFirstPrintMethodChange: true,
		setIsFirstPrintMethodChange: (isFirstPrintMethodChange) => set({ isFirstPrintMethodChange }),

		setSelectedVariantId: (id: number) => set({ selectedVariantId: id }),
		setSelectedSideId: (id: number) => set({ selectedSideId: id }),
		setVariantAndSide: (variantId, sideId) =>
			set({
				selectedSideId: sideId,
				selectedVariantId: variantId,
			}),

		sidesPrintMethods: Map(),
		setSidesPrintMethods: (sidesPrintTypes) => set({ sidesPrintMethods: sidesPrintTypes }),

		sidesRotations: Map(),
		preventAdaptForSideRotation: false,
		setSidesRotations: (sidesRotations) => set({ sidesRotations }),
		setPreventAdaptForSideRotation: (preventAdaptForSideRotation) => set({ preventAdaptForSideRotation }),
		rotateSide: (sideId, angle) => {
			let newRotations = get().sidesRotations;

			let newRotation = (newRotations.get(sideId) ?? 0) + angle;
			newRotation = newRotation % 360;

			newRotations = newRotations.set(sideId, newRotation);
			set({ sidesRotations: newRotations });
		},

		sidesCollages: Map(),
		setSidesCollages: (sidesCollages) => set({ sidesCollages }),
		setCollageForSide: (sideId: number, collageId: number | null) => {
			let newSidesCollages = get().sidesCollages;
			newSidesCollages = newSidesCollages.set(sideId, collageId);

			set({ sidesCollages: newSidesCollages });
		},

		resizableOptions: Map(),
		setSideOptions: (resizableOptions) => set({ resizableOptions }),
		setResizableOptionsForSide(variantId, sideId, options) {
			let newResizableOptions = get().resizableOptions;

			if (!newResizableOptions.get(variantId)) newResizableOptions = newResizableOptions.set(variantId, Map());

			newResizableOptions = newResizableOptions.set(
				variantId,
				newResizableOptions.get(variantId)!.set(sideId, options),
			);

			set({ resizableOptions: newResizableOptions });
		},

		sideAreaBackgrounds: Map(),
		setSideAreaBackgrounds: (sideId, areaId, fillColor, imageItemGuid) => {
			let newAreaBackgrounds = get().sideAreaBackgrounds;

			if (!newAreaBackgrounds.get(sideId)) newAreaBackgrounds = newAreaBackgrounds.set(sideId, Map());

			newAreaBackgrounds = newAreaBackgrounds.set(
				sideId,
				newAreaBackgrounds.get(sideId)!.set(areaId, { fillColor, imageItemGuid }),
			);

			set({ sideAreaBackgrounds: newAreaBackgrounds });
		},
		removeSideAreaBackgrounds: () => {
			let clearMap = get().sideAreaBackgrounds.map((innerMap) =>
				innerMap.map(() => ({ fillColor: null, imageItemGuid: null })),
			);
			set({ sideAreaBackgrounds: clearMap });
		},

		highlightSidesWarning: null,
		setHighlightSidesWarning: (warning) => set({ highlightSidesWarning: warning }),

		invalidItems: [],
		setInvalidItems: (items) => set({ invalidItems: items }),

		// mask-image
		/* maskMode: 1,
	setMaskMode: (mode: MaskedImageSelectionMode) => set({ maskMode: mode }), */

		selectedItemGuid: null,
		setSelectedItemGuid: (guid, options = {}) =>
			set((prev) => {
				if (prev.selectedItemGuid === guid) return prev;
				return {
					selectedItemGuid: guid,
					preventPageChangeOnItemChange: options.preventPageChange ?? false,
					// reset pivot on item change
					currentPivot: null,
				};
			}),

		lastActiveAreaPerSide: Map(),
		setLastActiveAreaPerSide: (sideId, areaId) => {
			let areasActive = get().lastActiveAreaPerSide;
			areasActive = areasActive.set(sideId, areaId);
			set({ lastActiveAreaPerSide: areasActive });
		},

		lastAddedGuid: null,
		setLastAddedGuid: (guid: string | null) => set({ lastAddedGuid: guid }),

		newItemGuids: new Set(),
		setNewItemGuids: (id) => set({ newItemGuids: new Set([...get().newItemGuids, id]) }),
		removeNewItemGuids: (id) => {
			const newItemGuidsModified = new Set(get().newItemGuids.values());
			newItemGuidsModified.delete(id);

			set({
				newItemGuids: newItemGuidsModified,
			});
		},

		mobileMenuPage: { page: 'none' },
		setMobileMenuPage: (page) => set({ mobileMenuPage: page }),

		menuPageHistory: [],
		menuPageOnExit: null,
		menuPage: { page: 'none' },
		setMenuPage: (page, updateBack = true, onExit = null) => {
			const getRealPage = (): MenuPageType => {
				if (isMobile()) {
					return page;
				}
				if (page.page === 'none') {
					return {
						page: 'design-elements',
					};
				}
				return page;
			};
			if (updateBack) {
				set((prev) => {
					if (prev.menuPage.page === 'none' || prev.menuPage.page === 'design-elements') {
						return {
							menuPageHistory: [{ page: 'design-elements' }],
						};
					}
					if (!prev.menuPageHistory.some((old) => isEqual(old, prev.menuPage))) {
						return {
							menuPageHistory: [prev.menuPage, ...prev.menuPageHistory],
						};
					}
					return { menuPageHistory: prev.menuPageHistory };
				});
			}
			get().menuPageOnExit?.();
			set({
				menuPage: getRealPage(),
				menuPageOnExit: onExit,
			});
		},
		goBackMenuPage: () => {
			set((prev) => {
				let [menuPage, ...menuPageHistory] = prev.menuPageHistory;

				if (
					menuPage.page === prev.menuPage.page &&
					(!('guid' in menuPage) || !('guid' in prev.menuPage) || menuPage.guid === prev.menuPage.guid)
				) {
					[menuPage, ...menuPageHistory] = menuPageHistory;
				}

				return {
					menuPage,
					menuPageHistory: menuPageHistory,
				};
			});
		},
		clearMenuHistory: (newFirstPage?: MenuPageType) => {
			if (newFirstPage) {
				set({ menuPageHistory: [newFirstPage] });
			} else {
				set({ menuPageHistory: [] });
			}
		},

		removeStaleEditItemFromHistory: (guid?: string) => {
			set((prev) => {
				const menuPageHistory = [...prev.menuPageHistory];
				let hasMenuPage = false;
				remove(menuPageHistory, (menuPage) => {
					if (guid && 'guid' in menuPage && menuPage.guid === guid) {
						// mi assicuro non ci siano doppioni.
						if (hasMenuPage) return true;
						hasMenuPage = true;
						return false;
					}
					return (
						menuPage.page === 'edit-text' ||
						menuPage.page === 'edit-image' ||
						menuPage.page === 'edit-shape' ||
						menuPage.page === 'edit-text-art'
					);
				});
				return { menuPageHistory };
			});
		},

		openEditMenuPageForItem: (item: CustomizerElement, updateBack: boolean = false) => {
			const oldMenuPage = get().menuPage;
			let newMenuPage: MenuPageType | null = null;
			if (isTextElement(item)) {
				newMenuPage = { page: 'edit-text', guid: item.guid };
			} else if (isImageElement(item)) {
				newMenuPage = { page: 'edit-image', guid: item.guid };
			} else if (isShapeElement(item)) {
				newMenuPage = { page: 'edit-shape', guid: item.guid };
			} else if (isTextArtElement(item)) {
				newMenuPage = { page: 'edit-text-art', guid: item.guid };
			}
			if (!newMenuPage || (oldMenuPage.page === newMenuPage.page && oldMenuPage.guid === newMenuPage.guid)) {
				return;
			}
			if (updateBack) {
				get().removeStaleEditItemFromHistory();
			}
			get().setMenuPage(newMenuPage, updateBack);
		},
		openEditMobileMenuPageForItem: (item: CustomizerElement) => {
			if (isTextElement(item)) {
				get().setMobileMenuPage({ page: 'text-edit', guid: item.guid });
			} else if (isImageElement(item)) {
				get().setMobileMenuPage({ page: 'image-edit', guid: item.guid });
			} else if (isShapeElement(item)) {
				get().setMobileMenuPage({ page: 'shape-edit', guid: item.guid });
			} else if (isTextArtElement(item)) {
				get().setMobileMenuPage({ page: 'textart-edit', guid: item.guid });
			}
		},

		zoom: null,
		setZoom: (zoom: number | null) => set({ zoom }),

		priceFormatter: new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}),
		setPriceFormatter: (priceFormatter) => set({ priceFormatter }),

		isPreview3DOpened: false,
		preview3DMode: 'small',
		setIsPreview3DOpened: (isPreview3DOpened) => set({ isPreview3DOpened }),
		setPreview3DMode: (preview3DMode) => set({ preview3DMode }),

		dialogs: [],
		addDialog: (id, dialog) =>
			set((prev) => {
				return {
					dialogs: [
						...prev.dialogs.filter((x) => x[0] !== id), // Remove the same id to avoid duplicates
						[id, dialog],
					],
				};
			}),
		removeDialog: (id) => set((prev) => ({ dialogs: prev.dialogs.filter((x) => x[0] !== id) })),

		vto: null,
		showVto: (id, vto) =>
			set(() => {
				return {
					vto: [id, vto],
				};
			}),
		removeVto: () => set(() => ({ vto: null })),

		photoEditors: [],
		showPhotoEditor: (id, photoEditor) =>
			set((prev) => {
				return {
					photoEditors: [
						...prev.photoEditors.filter((x) => x[0] !== id), // Remove the same id to avoid duplicates
						[id, photoEditor],
					],
				};
			}),
		removePhotoEditor: (id) => set((prev) => ({ photoEditors: prev.photoEditors.filter((x) => x[0] !== id) })),

		dropdowns: [],
		showDropdown: (id, dropdown) =>
			set((prev) => {
				return {
					dropdowns: [
						...prev.dropdowns.filter((x) => x[0] !== id), // Remove the same id to avoid duplicates
						[id, dropdown],
					],
				};
			}),
		removeDropdown: (id) => set((prev) => ({ dropdowns: prev.dropdowns.filter((x) => x[0] !== id) })),

		currentTemplate: null,
		currentDraft: null,
		editDesignId: null,

		setCurrentTemplate: (template) => set({ currentTemplate: template }),
		setCurrentDraft: (id) => set({ currentDraft: id }),
		setEditDesignId: (id) => set({ editDesignId: id }),

		recentColors: [],
		addRecentColor: (color) =>
			set((prev) => {
				let newColors = [...prev.recentColors];

				if (!newColors.find((c) => c.toLowerCase() === color.toLowerCase())) {
					if (newColors.length >= 10) newColors = newColors.slice(0, 9);

					newColors.unshift(color);
				}

				return {
					recentColors: newColors,
				};
			}),

		preventPageChangeOnItemChange: false,

		preventCollageElementsReadapt: false,
		setPreventCollageElementsReadapt: (preventCollageElementsReadapt) => set({ preventCollageElementsReadapt }),

		currentPivot: null,
		setCurrentPivot: (currentPivot) => set({ currentPivot }),

		nameNumberSelection: [],
		setNameNumberSelection: (nameNumberSelection) => set({ nameNumberSelection }),

		changedSide: new Set(),
		setChangedSide: (id) => set({ changedSide: new Set([...get().changedSide, id]) }),

		changedItemId: new Set(),
		setChangedItemId: (id) => set({ changedItemId: new Set([...get().changedItemId, id]) }),

		sidesEditedReady: new Set(),
		setSidesEditedReady: (id) => set({ sidesEditedReady: new Set([...get().sidesEditedReady, id]) }),

		selectionData: null,
		setSelectionData: (selectionData) => set({ selectionData }),
		recentFonts: [],
		setRecentFonts: (newRecentFonts) => {
			set({ recentFonts: newRecentFonts.slice(0, 5) });
		},
	}),
	shallow,
);

export const appStoreAtom = atomWithStore(useAppStore);

const removeRemovedInvalidItems = (newItems: string[]) => {
	useAppStore.setState(({ invalidItems }) => ({
		invalidItems: invalidItems.filter((x) => newItems.includes(x.guid)),
	}));
};
export const itemGuidsAtom = atomWithListeners<string[]>([], [removeRemovedInvalidItems]);
export const useItemsGuidsValue = () => useAtomValue(itemGuidsAtom);

const removeInvalidItemsWithProfanityWhenTouched = (newItem: CustomizerElement, oldItem: CustomizerElement) => {
	const isCurrentItemAndProfanity = (invalidItem: InvalidItem) =>
		invalidItem.guid === newItem.guid && invalidItem.reason === 'profanity';
	const isInvalidForProfanityReason = useAppStore.getState().invalidItems.some(isCurrentItemAndProfanity);
	if (!isInvalidForProfanityReason) return;
	const removeCurrentInvalidItemForProfanity = () => {
		useAppStore.setState(({ invalidItems }) => ({
			invalidItems: invalidItems.filter((invalidItem) => !isCurrentItemAndProfanity(invalidItem)),
		}));
	};
	if (isTextElement(newItem) && isTextElement(oldItem) && newItem.content !== oldItem.content) {
		removeCurrentInvalidItemForProfanity();
		return;
	}
	if (isImageElement(newItem) && isImageElement(oldItem) && newItem.imageId !== oldItem.imageId) {
		removeCurrentInvalidItemForProfanity();
	}
};

const undoHighlightsWhenTouched: AtomWithListnersCallback<CustomizerElement> = (
	newValue: CustomizerElement,
	oldValue: CustomizerElement,
	{ setSelf },
) => {
	if (oldValue.highlighted === true && newValue.highlighted === true) {
		setSelf((prev) => ({ ...prev, highlighted: false }));
	}
};

const markAsChangedWhenTouched: AtomWithListnersCallback<CustomizerElement> = (newValue, oldValue, { setSelf }) => {
	if (oldValue.isChanged) return;
	const isChangedTextElement = (newValue: TextElement, oldValue: TextElement) => {
		if (oldValue.sideId !== 0 || oldValue.realPositionX !== 0) return newValue.content !== oldValue.content;
	};
	const isChangedTextArtElement = (newValue: TextArtElement, oldValue: TextArtElement) => {
		return newValue.content !== oldValue.content;
	};
	const isChangedImageElement = (newValue: ImageElement, oldValue: ImageElement) => {
		return (
			newValue.imageId !== oldValue.imageId ||
			newValue.maskShapeId !== oldValue.maskShapeId ||
			newValue.colors !== oldValue.colors ||
			newValue.colorMappings !== oldValue.colorMappings ||
			newValue.maskShapeStrokeColor !== oldValue.maskShapeStrokeColor
		);
	};
	const isChangedShapeElement = (newValue: ShapeElement, oldValue: ShapeElement) => {
		return (
			newValue.fillColor !== oldValue.fillColor ||
			// newValue.strokeWidth !== oldValue.strokeWidth ||
			// newValue.positionX !== oldValue.positionX ||
			// newValue.positionY !== oldValue.positionY ||
			newValue.filledImageId !== oldValue.filledImageId ||
			newValue.strokeColor !== oldValue.strokeColor
		);
	};
	if (isTextElement(newValue) && isTextElement(oldValue) && isChangedTextElement(newValue, oldValue)) {
		setSelf((prev) => ({
			...prev,
			isChanged: true,
		}));
		return;
	}
	if (isTextArtElement(newValue) && isTextArtElement(oldValue) && isChangedTextArtElement(newValue, oldValue)) {
		setSelf((prev) => ({
			...prev,
			isChanged: true,
		}));
		return;
	}
	if (isImageElement(newValue) && isImageElement(oldValue) && isChangedImageElement(newValue, oldValue)) {
		setSelf((prev) => ({
			...prev,
			isChanged: true,
		}));
	}
	if (isShapeElement(newValue) && isShapeElement(oldValue) && isChangedShapeElement(newValue, oldValue)) {
		setSelf((prev) => ({
			...prev,
			isChanged: true,
		}));
	}
};

const defaultItem = (guid: string) =>
	({
		guid,
		type: 'text',
		syncGuid: '',
		name: '',
		color: '#000000',
		fontFamily: 'Arial',
		fontSize: 46,
		justification: 'center',
		shadowAngle: null,
		shadowBlur: null,
		shadowColor: null,
		bold: false,
		italic: false,
		sideId: 0,
		content: '',
		selected: false,
		colors: undefined,
		colorMappings: [],
		realPositionX: 0,
		realPositionY: 0,
		realRotation: 0,
		replaceWidth: 1,
		replaceHeight: 1,
		positionX: 0,
		positionY: 0,
		width: 1,
		height: 1,
		rotation: 0,
		index: 0,
		collageBoxId: null,
		isChanged: false,
	} as CustomizerElement);
export const itemsFamily = atomFamily((guid: string) => {
	return atomWithListeners(defaultItem(guid), [
		removeInvalidItemsWithProfanityWhenTouched,
		undoHighlightsWhenTouched,
		markAsChangedWhenTouched,
	]);
});

export const useItemValue = (guid: string) => {
	return useAtomValue(itemsFamily(guid));
};

export const useGetItem = (guid: string) => {
	return useAtomCallback((get) => get(itemsFamily(guid)));
};

export const useItemValueWithSelector = <Slice>(
	guid: string,
	selector: (item: CustomizerElement) => Slice,
	equalityFn?: (a: Slice, b: Slice) => boolean,
): Slice => {
	return useAtomValue(
		useMemo(() => selectAtom(itemsFamily(guid), selector, equalityFn), [equalityFn, guid, selector]),
	);
};

export const itemsSelectorFamily = atom(
	(get) => get(itemGuidsAtom).map((guid) => get(itemsFamily(guid))),
	(_, set, items: CustomizerElement[]) => {
		const guids = items.map((item) => item.guid);
		set(itemGuidsAtom, guids);
		for (const item of items) {
			set(itemsFamily(item.guid), item);
		}
	},
);

export const useItemsValue = () => {
	return useAtomValue(itemsSelectorFamily);
};

export type ItemsValueSelector<T> = (items: CustomizerElement[]) => T;
export const useItemsValueWithSelector = <Slice>(
	selector: ItemsValueSelector<Slice>,
	equalityFn?: (a: Slice, b: Slice) => boolean,
): Slice => {
	return useAtomValue(useMemo(() => selectAtom(itemsSelectorFamily, selector, equalityFn), [equalityFn, selector]));
};

export const useGetItems = () => {
	return useAtomCallback(useCallback((get) => get(itemsSelectorFamily), []));
};

export const useAddItem = () => {
	const setItems = useSetAtomWithPrev(itemsSelectorFamily);
	const commitState = useUndoRedoCommit();

	return useCallback(
		(item: CustomizerElement, preventUndoRedo: boolean = false) => {
			setItems((prev) => {
				const regularItems = prev.filter(
					(item) => !item.constraints?.isAlwaysOnTop && !item?.constraints?.isAlwaysOnBottom,
				);
				const alwaysOnTopItems = prev.filter((item) => item.constraints?.isAlwaysOnTop);
				const alwaysOnBottomItems = prev.filter((item) => item.constraints?.isAlwaysOnBottom);

				return [
					...regularItems,
					...alwaysOnBottomItems.map((item) => {
						return {
							...item,
							index: item.index - 1,
						};
					}),
					{ ...item, index: getNewZIndex(prev, item.sideId) },
					...alwaysOnTopItems.map((item) => {
						return {
							...item,
							index: item.index + 1,
						};
					}),
				];
			});
			if (!preventUndoRedo) {
				commitState();
			}
		},
		[commitState, setItems],
	);
};

export const useSetItems = () => {
	const setItems = useSetAtomWithPrev(itemsSelectorFamily);
	const commitState = useUndoRedoCommit();
	return useCallback(
		(cb: (items: CustomizerElement[]) => CustomizerElement[], preventUndoRedo: boolean = false) => {
			setItems(cb);
			if (!preventUndoRedo) {
				commitState();
			}
		},
		[commitState, setItems],
	);
};

export const useUpdateItem = () => {
	const { getSelectableColorsForText } = useZakekeHelpers();
	const [sidesPrintMethods] = useAppStore((x) => [x.sidesPrintMethods]);
	const { printTypesWithEngraving } = useZakekeEngraving();
	const setItems = useSetAtomWithPrev(itemsSelectorFamily);
	const commitState = useUndoRedoCommit();

	return useCallback(
		(guid: string, cb: (prev: CustomizerElement) => Partial<AllElements>, preventUndoRedo: boolean = false) => {
			setItems((prev) => {
				const index = prev.findIndex((x) => x.guid === guid);
				const newItems = [...prev];
				const updateForItem = cb(newItems[index]);
				newItems[index] = {
					...newItems[index],
					...updateForItem,
				}; // Item updated

				const itemChanges = differenceWith(toPairs(newItems[index]), toPairs(prev[index]), isEqual).map(
					(x) => x[0],
				);

				// If element has ColorSync tag and updates are for colors...
				if (
					newItems[index].tagType === DesignItemTagType.SyncColors &&
					(itemChanges.indexOf('color') >= 0 ||
						itemChanges.indexOf('colors') >= 0 ||
						itemChanges.indexOf('colorMappings') >= 0)
				) {
					const updatedItem = newItems[index];
					const originalItem = prev[index];
					const relatedTaggedItems = newItems.filter(
						(x) =>
							x.tagType === DesignItemTagType.SyncColors &&
							x.tag === updatedItem.tag &&
							x.guid !== updatedItem.guid,
					);

					if (relatedTaggedItems && relatedTaggedItems.length > 0) {
						relatedTaggedItems.forEach((relatedItem) => {
							const indexRelated = newItems.findIndex((x) => x.guid === relatedItem.guid);
							const relatedPrintType = sidesPrintMethods.get(relatedItem.sideId);

							//engraving or effects on image/texts...
							// TODO remove async
							const selectableColors = getSelectableColorsForText(
								relatedItem.sideId,
								null,
								relatedPrintType,
								false,
							);

							const [printTypeEffect] = printTypesWithEngraving.filter((x) => x.id === relatedPrintType);
							const printTypeHasEffect = !!printTypeEffect;
							const canEngravingEffectChangeTextColor = !(
								printTypeHasEffect && printTypeEffect.previewDesignEffect.hasTextOverlay
							);
							const canEngravingEffectChangeImageColor = !(
								printTypeHasEffect &&
								printTypeEffect.previewDesignEffect.imageEffectTypeName.toLowerCase() !== 'none'
							);

							if (
								itemChanges.indexOf('colors') >= 0 &&
								isImageElement(updatedItem) &&
								isImageElement(originalItem) &&
								isImageElement(relatedItem) &&
								canEngravingEffectChangeImageColor
							) {
								// svg!
								const diffIndex = differenceIndex(originalItem.colors, updatedItem.colors);
								const updateColor = updatedItem.colors[diffIndex];
								const originalColor = updatedItem.initialColors[diffIndex];

								if (
									selectableColors &&
									selectableColors.length > 0 &&
									selectableColors.find((x) => x.hex === updateColor) === undefined
								) {
									return; // new color is not allowed by printType
								}
								if (relatedItem.colorMappings && relatedItem.colorMappings.length > 0) {
									const updateColorMappings = cloneDeep(relatedItem.colorMappings);
									const updateColorIndex = updateColorMappings.findIndex(
										(x) => x.src === originalColor,
									);
									updateColorMappings[updateColorIndex].dest = updateColor;
									newItems[indexRelated] = {
										...relatedItem,
										...{ colorMappings: updateColorMappings },
									};
								} else {
									const updateColors = cloneDeep(relatedItem.colors);
									const substitutionIndex = relatedItem.initialColors.findIndex(
										(x) => x === originalColor,
									);
									updateColors[substitutionIndex] = updateColor;
									newItems[indexRelated] = { ...relatedItem, ...{ colors: updateColors } };
								}
							} else if (
								itemChanges.indexOf('colorMappings') >= 0 &&
								isImageElement(updatedItem) &&
								isImageElement(originalItem) &&
								isImageElement(relatedItem) &&
								canEngravingEffectChangeImageColor
							) {
								const diffIndex = differenceIndex(
									originalItem.colorMappings as any[],
									updatedItem.colorMappings as any[],
								);

								if (diffIndex !== -1 && updatedItem.colorMappings) {
									const updateColor = updatedItem.colorMappings[diffIndex].dest as string;
									const originalColor = updatedItem.colorMappings[diffIndex].src as string;

									if (
										selectableColors &&
										selectableColors.length > 0 &&
										selectableColors.find((x) => x.hex === updateColor) === undefined
									) {
										return; // new color is not allowed by printType
									}
									if (relatedItem.colorMappings && relatedItem.colorMappings.length > 0) {
										const updateColorMappings = cloneDeep(relatedItem.colorMappings);
										const updateColorIndex = updateColorMappings.findIndex(
											(x) => x.src === originalColor,
										);
										updateColorMappings[updateColorIndex].dest = updateColor;
										newItems[indexRelated] = {
											...relatedItem,
											...{ colorMappings: updateColorMappings },
										};
									} else {
										const updateColors = cloneDeep(relatedItem.colors);
										const updateColorIndex = relatedItem.initialColors.findIndex(
											(x) => x === originalColor,
										);
										updateColors[updateColorIndex] = updateColor;
										newItems[indexRelated] = { ...relatedItem, ...{ colors: updateColors } };
									}
								}
							} else if (
								itemChanges.indexOf('color') >= 0 &&
								isTextElement(updatedItem) &&
								isTextElement(relatedItem) &&
								canEngravingEffectChangeTextColor
							) {
								// text!
								const { color: updateColor } = updateForItem as Partial<TextElement>;
								if (
									selectableColors &&
									selectableColors.length > 0 &&
									selectableColors.find((x) => x.hex === updateColor) === undefined
								) {
									return; // new color is not allowed by printType
								}
								newItems[indexRelated] = { ...relatedItem, ...{ color: updateColor } };
							}
						});
					}
				}
				return newItems;
			});
			if (!preventUndoRedo) {
				commitState();
			}
		},
		[commitState, getSelectableColorsForText, printTypesWithEngraving, setItems, sidesPrintMethods],
	);
};

export const useRemoveItem = () => {
	const setItems = useSetAtomWithPrev(itemsSelectorFamily);
	const commitState = useUndoRedoCommit();
	return useCallback(
		(guid: string, preventUndoRedo: boolean = false) => {
			setItems((prev) => {
				return prev.filter((x) => x.guid !== guid);
			});
			if (!preventUndoRedo) {
				commitState();
			}
		},
		[commitState, setItems],
	);
};

export const useBringToFront = () => {
	const setItems = useSetAtomWithPrev(itemsSelectorFamily);
	const commitState = useUndoRedoCommit();
	return useCallback(
		(guid: string) => {
			setItems((prev) => {
				return bringToFront(prev, guid);
			});
			commitState();
		},
		[commitState, setItems],
	);
};

export const useSendToBack = () => {
	const setItems = useSetAtomWithPrev(itemsSelectorFamily);
	const commitState = useUndoRedoCommit();
	return useCallback(
		(guid: string) => {
			setItems((prev) => {
				return sendToBack(prev, guid);
			});
			commitState();
		},
		[commitState, setItems],
	);
};

export const useMoveItemAbove = () => {
	const setItems = useSetAtomWithPrev(itemsSelectorFamily);
	const commitState = useUndoRedoCommit();
	return useCallback(
		(guid: string, aboveGuid: string) => {
			setItems((prev) => {
				return moveItemToAbove(prev, guid, aboveGuid);
			});
			commitState();
		},
		[commitState, setItems],
	);
};

export const useMoveItemBelow = () => {
	const setItems = useSetAtomWithPrev(itemsSelectorFamily);
	const commitState = useUndoRedoCommit();
	return useCallback(
		(guid: string, belowGuid: string) => {
			setItems((prev) => {
				return moveItemToBelow(prev, guid, belowGuid);
			});
			commitState();
		},
		[commitState, setItems],
	);
};

export const useReplaceColor = () => {
	const setItems = useSetAtomWithPrev(itemsSelectorFamily);
	const [sideAreaBackgrounds, setSideAreaBackgrounds] = useAppStore((x) => [
		x.sideAreaBackgrounds,
		x.setSideAreaBackgrounds,
	]);

	return useCallback(
		(sideId: number, oldColor: string, newColor: string) => {
			// change background color
			if (sideAreaBackgrounds) {
				sideAreaBackgrounds.forEach((side) => {
					side.forEach((area, areaId) => {
						if (area.fillColor && area.fillColor === oldColor)
							setSideAreaBackgrounds(sideId, areaId, newColor, null);
					});
				});
			}

			setItems((prev) => {
				const newItems = cloneDeep(prev);

				for (const item of newItems) {
					if (item.sideId !== sideId) continue;

					if (isTextElement(item)) {
						if (item.color === oldColor) item.color = newColor;
						if (item.shadowColor === oldColor) item.shadowColor = newColor;
					} else if (isImageElement(item)) {
						item.colorMappings = item.colorMappings?.map((x) => ({
							...x,
							dest: x.dest === oldColor ? newColor : x.dest,
						}));
						item.colors = item.colors?.map((x) => (x === oldColor ? newColor : x));
					} else if (isShapeElement(item)) {
						if (item.fillColor === oldColor) item.fillColor = newColor;
						if (item.strokeColor === oldColor) item.strokeColor = newColor;
						item.filledImageColorMapping = item.filledImageColorMapping?.map((x) => ({
							...x,
							dest: x.dest === oldColor ? newColor : x.dest,
						}));
						item.filledImageColors = item.filledImageColors?.map((x) => (x === oldColor ? newColor : x));
					} else if (isTextArtElement(item)) {
						if (item.fillColor === oldColor) item.fillColor = newColor;
						if (item.strokeColor === oldColor) item.strokeColor = newColor;
					}
				}

				return newItems;
			});
		},
		[setItems, setSideAreaBackgrounds, sideAreaBackgrounds],
	);
};

export const useHighlightItem = () => {
	const setItems = useSetAtomWithPrev(itemsSelectorFamily);
	return useCallback(
		(guid: string) => {
			setItems((prev) => {
				return prev.map((item) => {
					if (item.guid !== guid) return item;
					return { ...item, highlighted: true };
				});
			});
		},
		[setItems],
	);
};

export const useUnhighlightItem = () => {
	const setItems = useSetAtomWithPrev(itemsSelectorFamily);
	return useCallback(
		(guid: string) => {
			setItems((prev) => {
				if (!prev.some((item) => item.highlighted)) return prev;
				return prev.map((item) => {
					if (item.guid !== guid) return item;
					if (!item.highlighted) return item;
					return { ...item, highlighted: false };
				});
			});
		},
		[setItems],
	);
};

export const useClearHighlightedItems = () => {
	const setItems = useSetAtomWithPrev(itemsSelectorFamily);
	return useCallback(() => {
		setItems((prev) => {
			return prev.map((item) => {
				return { ...item, highlighted: false };
			});
		});
	}, [setItems]);
};
