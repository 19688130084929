import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { darken } from 'polished';

import { MOBILE_BREAKPOINT_PX } from '../../shared/helpers';
import {
	pageCloseClickDecorator,
	PageCloseClickOptions,
	actionDecorator,
	ActionOptions,
} from '../../shared/helpers.ui';
import { ReactComponent as LeftArrowIcon } from '../../shared/icons/arrow-left-solid.svg';
import { ReactComponent as TimesIcon } from '../../shared/icons/times-solid.svg';
import { ReactComponent as MenuExpand } from '../../shared/icons/ic-menu-expand.svg';
import { ReactComponent as MenuContract } from '../../shared/icons/ic-menu-contract.svg';
import { useAppStore } from '../../state/store';
import { Icon, Input } from '../globals';
import { useIsMobile } from '../../hooks/useLayoutCheck';

// Icons
const MenuPageContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
`;

const Title = styled.h1`
	font: ${(props) => props.theme.fonts.large200bold};
	margin-top: 0;
	margin-bottom: 0;
	display: flex;
	align-items: center;
	position: relative;
	text-wrap: balance;

	@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
		font: ${(props) => props.theme.fonts.large100bold};
	}
	${Icon} {
		color: ${(props) => props.theme.colors.iconSecondary};
		@media (hover) {
			&:hover {
				color: ${(props) => props.theme.colors.iconSecondaryState};
			}
		}
	}
`;

const BackIcon = styled(Icon)`
	margin-inline-end: 20px;
	cursor: pointer;
	color: ${(props) => props.theme.colors.iconMain};

	@media (hover) {
		&:hover {
			color: ${(props) => props.theme.colors.primary};
		}
	}
`;

const ExpandIcon = styled(Icon)``;

const CloseIcon = styled(Icon)``;

const TitleIcon = styled.div`
	&:not(:first-child) {
		border-inline-start: 1px solid ${(props) => props.theme.colors.borders};
		padding-inline-start: 10px;
		margin-inline-start: 10px;
	}
`;

const TitleIcons = styled.div`
	display: flex;
	margin-inline-start: auto;
	transform: translateX(4px);

	${ExpandIcon}, ${CloseIcon} {
		width: 20px;
		height: 20px;
		cursor: pointer;
		color: ${(props) => props.theme.colors.iconSecondary};

		@media (hover) {
			&:hover {
				color: ${(props) => props.theme.colors.iconSecondaryState};
			}
		}
	}
`;

export const MenuPageHeader = styled.div<{ $titleSmallerPad?: boolean }>`
	padding: 12px ${(props) => props.theme.spacing.horizontalPadder} 10px;
	color: ${(props) => props.theme.colors.textSecondary};

	@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
		padding: 8px ${(props) => (props.$titleSmallerPad ? '12px' : props.theme.spacing.horizontalPadderMobile)} 10px;
	}
`;

const MenuFooter = styled.div<{ $backgroundColor?: string; $smallerPad?: boolean }>`
	padding: 8px ${(props) => props.theme.spacing.horizontalPadder} 12px;
	background-color: ${(props) =>
		props.$backgroundColor ? props.$backgroundColor : props.theme.colors.backgroundMain};
	@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
		padding: 8px ${(props) => (props.$smallerPad ? '12px' : props.theme.spacing.horizontalPadderMobile)} 20px;
	}
`;

const MenuPageContent = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
	min-height: 0;
	padding-bottom: 1px;
	padding-top: 16px;
	overflow: auto;
	border-top-left-radius: calc(${(props) => props.theme.measures.borderRadius} * 6);
	border-top-right-radius: calc(${(props) => props.theme.measures.borderRadius} * 6);
	overflow-x: hidden;
	position: relative;
	background-color: ${(props) => props.theme.colors.backgroundMain};
	@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
		padding-top: 24px;
	}
`;

export const HorizontalPadder = styled.div<{ $disableGutters?: boolean }>`
	display: flex;
	flex-direction: column;
	${(props) =>
		!props.$disableGutters &&
		css`
			padding-inline: ${props.theme.spacing.horizontalPadder};
			@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
				padding-inline: ${props.theme.spacing.horizontalPadderMobile};
			}
		`}
`;

// const ToolsList = styled(List)`
// 	min-width: 250px;
// `;

export const MenuInnerWrapper = styled.div`
	padding-bottom: 24px;
`;

export type MenuPageProps = {
	title?: React.ReactNode;
	header?: React.ReactNode;
	children?: React.ReactNode;
	footer?: React.ReactNode;
	showBackButton?: boolean;
	onBackButtonClick?: (e: ActionOptions) => void;
	onCloseClick?: (e: PageCloseClickOptions) => void;
	expanded?: boolean;
	onExpand?: () => void;
	footerBackgroundColor?: string;
	footerSmallerPad?: boolean;
	titleSmallerPad?: boolean;
	hideCloseButton?: boolean;
};

export const MenuPage: FC<MenuPageProps> = ({
	title,
	header,
	children,
	footer,
	showBackButton,
	onCloseClick,
	onBackButtonClick,
	expanded,
	onExpand,
	footerBackgroundColor,
	footerSmallerPad,
	titleSmallerPad,
	hideCloseButton,
}) => {
	const isMobile = useIsMobile();
	const [setMenuPage, menuPage, menuPageHistory, setSelectedItemGuid, goBackMenuPage] = useAppStore((x) => [
		x.setMenuPage,
		x.menuPage,
		x.menuPageHistory,
		x.setSelectedItemGuid,
		x.goBackMenuPage,
	]);

	const handleBackClick = () => {
		const { isDefaultPrevented } = actionDecorator(onBackButtonClick);
		if (isDefaultPrevented) return;
		if (menuPageHistory.length > 1) goBackMenuPage();
		else setMenuPage({ page: 'design-elements' });
	};

	const handleCloseClick = async () => {
		const { preventDefaultCloseBehaviour } = pageCloseClickDecorator(onCloseClick);
		if (preventDefaultCloseBehaviour) return;
		setSelectedItemGuid(null, {
			// If we have at last one item and the page is not design elements, then return to design elements
			// Requested this behaviour by Carlo
			preventPageChange: true,
		});
		setMenuPage({
			page: menuPage.page !== 'design-elements' && !isMobile ? 'design-elements' : 'none',
		});
	};

	return (
		<MenuPageContainer>
			<MenuPageHeader $titleSmallerPad={titleSmallerPad}>
				<Title>
					{!isMobile && showBackButton && (
						<BackIcon onClick={handleBackClick}>
							<LeftArrowIcon />
						</BackIcon>
					)}
					{title}
					<TitleIcons>
						{!isMobile && onExpand && (
							<TitleIcon>
								<ExpandIcon onClick={onExpand}>
									{expanded ? <MenuContract /> : <MenuExpand />}
								</ExpandIcon>
							</TitleIcon>
						)}
						{hideCloseButton !== true && (
							<TitleIcon>
								<CloseIcon onClick={handleCloseClick}>
									<TimesIcon />
								</CloseIcon>
							</TitleIcon>
						)}
					</TitleIcons>
				</Title>
				{header}
			</MenuPageHeader>
			<MenuPageContent>{children}</MenuPageContent>
			{footer && (
				<MenuFooter $backgroundColor={footerBackgroundColor} $smallerPad={footerSmallerPad}>
					{footer}
				</MenuFooter>
			)}
		</MenuPageContainer>
	);
};

const SectionContainer = styled.div<{ $noLabelMinWidth?: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	border-bottom: 1px solid ${(props) => props.theme.colors.borders};
	padding: 3px 0;
	font-size: 13px;
	min-height: 40px;

	> label {
		margin-right: auto;
		min-width: 45%;
		${(props) =>
			props.$noLabelMinWidth &&
			css`
				min-width: unset;
			`};
	}

	> div {
		flex: 1;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	${Input} {
		border-color: transparent transparent ${(props) => props.theme.colors.borders};
		text-align: end;
		padding: 7px 10px;
		min-height: 30px;
		border-radius: 0;

		&:focus {
			border-color: ${(props) => props.theme.colors.primary};
			border-radius: ${(props) => props.theme.measures.borderRadius};
		}
	}
`;

export const SectionGroup = styled.div`
	${SectionContainer}:first-child:not(:only-child) {
		border-bottom: 0 none;
	}
	${SectionContainer}:last-child:not(:only-child) {
		border-top: 0 none;
	}
	${SectionContainer}:not(:first-child):not(:last-child) {
		border: 0 none;
		padding-top: 3px;
		padding-bottom: 3px;
		min-height: 40px;
	}
`;

type SectionProps = {
	label: string;
	children: React.ReactNode;
	noLabelMinWidth?: boolean;
};
const Section: FC<SectionProps> = ({ label, noLabelMinWidth, children }) => (
	<SectionContainer $noLabelMinWidth={noLabelMinWidth}>
		<label>{label}</label>
		<div>{children}</div>
	</SectionContainer>
);

export const SubSection = styled.div`
	background-color: ${(props) => darken(0.035, props.theme.colors.backgroundMain)};
	${SectionContainer} {
		padding-left: 16px;
		padding-right: 16px;
	}
`;

export const SectionPageItem = styled.div`
	&:last-child {
		padding-bottom: 30px;
	}
`;

export const SectionPageItemTitle = styled.header`
	h3 {
		margin-top: 0;
		margin-bottom: 10px;
		font: ${(props) => props.theme.fonts.large200bold};
	}
`;

export const SectionPageItemIntro = styled.div`
	display: flex;
	justify-content: space-between;
	padding-bottom: 8px;
	border-bottom: 1px solid ${(props) => props.theme.colors.borders};

	h3 {
		margin-top: 12px;
		margin-bottom: 12px;
		font: ${(props) => props.theme.fonts.large200bold};
	}
`;

export const SectionPagePreviewWrapper = styled.div`
	width: 42px;
	height: 48px;
`;

export const SectionPagePreviewSize = styled.div`
	margin-inline: 15px auto;
	align-self: center;
	font-size: 12px;
	p {
		font-size: 14px;
		margin: 2px 0 0;
	}
`;

export const SectionPagePreview = styled.img`
	width: 42px;
	height: 48px;
	object-fit: contain;
	object-position: left center;
`;

export const SectionPageItemActions = styled.div`
	display: flex;
`;

export const SectionPageItemButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 32px;
	min-width: 32px;
	padding: 4px;
	border: none;
	background: transparent;
	color: ${(props) => props.theme.colors.iconMain};
	cursor: pointer;

	@media (hover) {
		&:hover {
			color: ${(props) => props.theme.colors.primary};
		}
	}
`;

export const SectionPageItemControls = styled.div``;

export default Section;
