import React, { FC, useMemo } from 'react';
import { ToolIconKey } from '../index';

import { ReactComponent as BackgroundIcon } from './icons/background.svg';
import { ReactComponent as CollagesIcon } from './icons/collages.svg';
import { ReactComponent as DesignElementsIcon } from './icons/design-elements.svg';
import { ReactComponent as DraftsIcon } from './icons/drafts.svg';
import { ReactComponent as ClipartsIcon } from './icons/cliparts.svg';
import { ReactComponent as ShapesIcon } from './icons/shapes.svg';
import { ReactComponent as Templates } from './icons/templates.svg';
import { ReactComponent as TextArtIcon } from './icons/text-art.svg';
import { ReactComponent as TextIcon } from './icons/text.svg';
import { ReactComponent as UploadImageIcon } from './icons/upload-image.svg';
import { ReactComponent as CartIcon } from './icons/cart.svg';
import { ReactComponent as VariantsIcon } from './icons/switch-variant.svg';
import { ReactComponent as PrintingMethodsIcon } from './icons/printing-methods.svg';

export type ThemeIconKey = ToolIconKey;

const getDefaultIconComponentFromKey = (key: ThemeIconKey) => {
	switch (key) {
		case 'text':
			return TextIcon;
		case 'textart':
			return TextArtIcon;
		case 'collages':
			return CollagesIcon;
		case 'design-elements':
			return DesignElementsIcon;
		case 'switch-variant':
			return VariantsIcon;
		case 'printing-methods':
			return PrintingMethodsIcon;
		case 'background':
			return BackgroundIcon;
		case 'cliparts':
			return ClipartsIcon;
		case 'drafts':
			return DraftsIcon;
		case 'templates':
			return Templates;
		case 'shapes':
			return ShapesIcon;
		case 'upload-image':
			return UploadImageIcon;
		case 'cart':
			return CartIcon;
		default:
			return React.Fragment;
	}
};

export type ThemedIconProps = {
	iconUrl?: string;
	defaultIconKey: ThemeIconKey;
} & React.SVGProps<SVGSVGElement>;
const ThemedIcon: FC<ThemedIconProps> = ({ iconUrl, defaultIconKey, ...props }) => {
	const DefaultIcon = useMemo(() => {
		return getDefaultIconComponentFromKey(defaultIconKey);
	}, [defaultIconKey]);
	if (iconUrl) {
		return (
			<svg xmlns='http://www.w3.org/2000/svg' {...props}>
				<image href={iconUrl} width='100%' height='100%' />
			</svg>
		);
	}
	return <DefaultIcon />;
};

export default ThemedIcon;
