import { useBackgroundEditingTools } from '../hooks/useBackgroundEditingTools';
import { AvailableTools } from '../hooks/useAvailableTools';

export type BackgroundEditingMenuTabId = 'color' | 'upload' | 'gallery';

export const backgroundEnabledTabs = (
	backgroundRules: ReturnType<typeof useBackgroundEditingTools>,
	availableTools: {
		isAnyUploadEnabled: AvailableTools['isAnyUploadEnabled'];
		isUploadFromYourComputerVisible: AvailableTools['isUploadFromYourComputerVisible'];
	},
	currentColor?: string | null,
	preventTabs?: BackgroundEditingMenuTabId[],
) => {
	if (!backgroundRules) return [];
	const backgroundTabs = [];
	if ((backgroundRules.colorList !== null || currentColor) && !preventTabs?.includes('color')) {
		backgroundTabs.push('color');
	}
	if (
		backgroundRules.enableImageCustomerGallery &&
		availableTools.isAnyUploadEnabled &&
		availableTools.isUploadFromYourComputerVisible &&
		!preventTabs?.includes('upload')
	) {
		backgroundTabs.push('upload');
	}
	if (backgroundRules.enableImageSellerGallery && !preventTabs?.includes('gallery')) {
		backgroundTabs.push('gallery');
	}
	return backgroundTabs as BackgroundEditingMenuTabId[];
};
