import { useAppStore } from '../../state/store';
import { useZakekeAddToCart, useZakekeTranslations } from '@zakeke/zakeke-customizer-react';
import useProfanityFilter from '../../hooks/useProfanityFilter';
import Loading from '../widgets/loading';
import React, { useMemo } from 'react';

const useAddToCartLoadingText = () => {
	const { T } = useZakekeTranslations();
	return useMemo(
		() => [
			{
				text: T._('Adding the product to your cart...'),
				duration: 2000,
			},
			{
				text: T._('Almost there! Preparing your cart...'),
				duration: 5000,
			},
			<span>
				{T._('This is taking slightly longer than expected, but don’t worry-we’re on it!')}
				<br />
				{T._(' Please don’t close the page.')}
			</span>,
		],
		[T],
	);
};

const GlobalLoader = () => {
	const isBootCompleted = useAppStore((state) => state.isBootCompleted);
	const { isAddingToCart } = useZakekeAddToCart();
	const { isCheckingForProfanity } = useProfanityFilter();
	const addToCartLoadingText = useAddToCartLoadingText();
	if (!isBootCompleted) {
		return <Loading />;
	}
	if (isCheckingForProfanity) {
		return <Loading />;
	}
	if (isAddingToCart) {
		return <Loading darkBack forceDarkBackText text={addToCartLoadingText} />;
	}

	return null;
};

export default GlobalLoader;
