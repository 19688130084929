import { ColorMapping, Image, ItemConstraints, ZakekeMatrix } from '@zakeke/zakeke-customizer-react'; // Reuse library constraints, they are ok
import { ImageGalleryImage, ImageGalleryMacroCategory } from './widgets/imagesGallery';
import { ColorPickerPaletteColor } from './widgets/colorpicker/interfaces';
import React from 'react';

export interface CustomizerElement {
	guid: string;
	sideId: number;
	syncGuid: string;
	type: 'text' | 'image' | 'shape' | 'text-art';
	tag?: string;
	tagType?: number;
	name: string;
	isChanged?: boolean;
	highlighted?: boolean;

	// Width and height for replace, so that it will keep the original bounds
	// and the image can be replaced an undefined amount of times
	replaceWidth: number;
	replaceHeight: number;

	// Rendered transform which take care of snaps
	positionX: number;
	positionY: number;
	realPositionX: number;
	realPositionY: number;
	width: number;
	height: number;
	rotation: number;
	matrix?: ZakekeMatrix | null;
	realMatrix?: ZakekeMatrix | null;

	index: number;
	constraints?: ItemConstraints | undefined;

	collageBoxId: number | null;

	isFromTemplate?: boolean;
	isTextBox?: boolean;
	adaptTextBoxFontSize?: boolean;
	textBoxRectangle?: Rectangle | null;

	isCurvedText?: boolean;
	curvedTextPointFirst?: { x: number; y: number } | null;
	curvedTextPointMiddle?: { x: number; y: number } | null;
	curvedTextPointEnd?: { x: number; y: number } | null;

	snapGuideX?: number | null;
	snapGuideY?: number | null;

	flipX?: boolean | null;
	flipY?: boolean | null;
}

export interface TextElement extends CustomizerElement {
	shouldCheckMatrixSize?: boolean;
	content: string;
	color: string;
	bold: boolean;
	italic: boolean;
	justification: 'left' | 'center' | 'right' | 'justify';
	verticalAlignment: 'top' | 'middle' | 'bottom';
	fontFamily: string;
	fontSize?: number;
	shadowColor?: string | null;
	shadowAngle?: number;
	shadowBlur?: number;
	shadowDistance?: number;
	lineSpacing?: number;
	letterSpacing?: number;
	originalContent?: string;
	strokeWidth: number;
	strokeColor: string;
}

export type ImageUploadSource = 'images-gallery' | 'clipart-gallery' | 'seller-gallery' | 'premium-gallery';

export interface ImageElement extends CustomizerElement {
	imageId: number;
	initialColors: string[];
	colors: string[];
	colorIds?: number[];
	colorMappings?: ColorMapping[];
	isClipart: boolean;
	flipX: boolean;
	flipY: boolean;
	maskShapeId: number | null;
	maskShapeStrokeColor: string;
	maskShapeStrokeWidth: number;
	maskShapeMatrix: ZakekeMatrix | null;
	maskShapeInitialBounds?: any;
	isBackgroundForArea?: number | null;
	preferredHeight?: number | null;
	preferredWidth?: number | null;
	isFromSeller?: boolean;
	isVector?: boolean;
	imageUploadSource?: ImageUploadSource;
	imageCategoryId?: number | null;
}

export interface ShapeElement extends CustomizerElement {
	shapeId: number;
	fillColor: string;
	strokeColor: string;
	strokeWidth: number;
	scaleX: number;
	scaleY: number;
	isFillable: boolean;
	filledImageId: number | null;
	filledImageColors?: string[];
	filledImageInitialColors: string[];
	filledImageColorMapping?: ColorMapping[];
	imageUploadSource?: ImageUploadSource;
	imageCategoryId?: number | null;
	filledShapeImageMatrix: ZakekeMatrix | null;
}

export interface TextArtElement extends CustomizerElement {
	content: string;
	typeId: number;
	fontFamilyId: number;
	fontFaceId: number;
	fillColor: string;
	strokeColor: string;
	strokeWidth: number;
	originalContent?: string;

	configuration: TextArtConfiguration;
}

export interface TextArtConfiguration {
	angleValue: number;
	curveValue: number;
	intensT: number;
	intensB: number;
	isTriangle: boolean;
	isBridge: boolean;
	isTop: boolean;
	isBottom: boolean;
	isMiddle: boolean;
}
export interface Mask {
	imageUrl: string;
	isDefault: boolean;
	maskImageUrl: string;
	name: string;
	pathDataUrl: string;
	position: null;
	shapeGuid: string;
	shapeID: number;
	tags: string;
	useAsMask: true;
}

export type AllElements = TextElement | ImageElement | ShapeElement | TextArtElement;

export const isTextElement = (element: CustomizerElement): element is TextElement => {
	return element.type === 'text';
};

export const isImageElement = (element: CustomizerElement): element is ImageElement => {
	return element.type === 'image';
};

export const isShapeElement = (element: CustomizerElement): element is ShapeElement => {
	return element.type === 'shape';
};

export const isTextArtElement = (element: CustomizerElement): element is TextArtElement => {
	return element.type === 'text-art';
};

export enum EventMessageType {
	ImageWarning = 1,
	AddToCartConfirm = 4,
	ExitWarning = 11,
	WelcomeMessage = 3,
	WelcomeMessageGuest = 10,
	ClippedElementWarning = 7,
	ImageCopyrightMessage = 8,
}

export type UseImagesResponse = {
	images: ImageGalleryImage[];
	fetching: boolean;
	hasMore: boolean;
	fetchMore: () => Promise<void> | undefined;
	error: Error | null;
};
export type UseImages = (
	categoryId: string | number | null,
	filter: string,
	query?: Record<string, string | number | null | boolean>,
) => UseImagesResponse;
export type UseCreateImage = () => (images: ImageGalleryImage) => Promise<Image>;

export interface ImageProvider {
	title: string;
	name: string;
	useCategories: () => ImageGalleryMacroCategory[];
	useImages: UseImages;
	useCreateImage: UseCreateImage;
	hasSearchByName: boolean;
}

export interface Rectangle {
	x: number;
	y: number;
	width: number;
	height: number;
}

export interface Point {
	x: number;
	y: number;
}

export interface BackgroundRule {
	colorList: ColorPickerPaletteColor[] | null;
	enableImageCustomerGallery: boolean;
	enableImageSellerGallery: boolean;
	imageTypeID: number | null;
	enableNoColor: boolean | null;
	enableAllColors: boolean;
}

export interface EditMenuItem {
	id: string;
	action: () => void;
	icon: React.ReactNode;
	label: string;
	disabled?: boolean;
}
