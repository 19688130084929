import { CustomizerLayoutContainer } from './customizerLayout';
import CustomizerMenuSkeleton from '../customizerMenu/customizerMenuSkeleton';
import Skeleton from '../../widgets/skeleton';
import styled from 'styled-components';

const ProductSkeletonContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding-bottom: 40px;
`;
const CustomizerSkeleton = () => (
	<CustomizerLayoutContainer>
		<CustomizerMenuSkeleton />
		<ProductSkeletonContainer>
			<Skeleton variant='rectangular' width='60%' height='60%' />
		</ProductSkeletonContainer>
	</CustomizerLayoutContainer>
);

export default CustomizerSkeleton;
